import { Component, OnInit } from '@angular/core';
import { ModelList } from '../../include/modellist'
import { FormBuilder, FormGroup, FormArray, FormControl  } from '@angular/forms';
import { Filter,FilterMode,Template,Origin } from '../../include/structures'
import { TemplatesService } from '../../lib/templates.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Globals } from '../../globals';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.sass']
})
export class TemplatesComponent extends ModelList<Template> implements OnInit {

  public Editor = ClassicEditor;
  editorOn=true;


  selectedTemplate:Template={} as Template;

  filter_type:Filter=new Filter();

  example:any;


  constructor(
    private fb1: FormBuilder,
    private templatesService: TemplatesService,
  ) {
    super(templatesService,fb1);
   }

  ngOnInit(): void {
    this.title='Template esportazioni';
    super.ngOnInit.apply(this, arguments); 

    this.filter_type.mode=FilterMode.normal;
    this.filter_type.fields=[];
    this.filter_type.fields.push("t.table");
    this.filter_type.value="";
    this.filter.push(this.filter_type);

    this.getItems();
  }


  addTemplate(){
    let r:Template={} as Template;
    r.status=1;

    
    if(this.filter_type.value!="")
      r.table=this.filter_type.value;
    this.selectedTemplate=r;
  }

  setRule(record:Template){
    
    this.selectedTemplate=Object.assign({}, record);
    this.selectedTemplate.originsArray=[];
    if(this.selectedTemplate.origins!="")
      this.selectedTemplate.originsArray=JSON.parse(this.selectedTemplate.origins);
    

  }

  confirmRule(oncomplete=null):void{
    
    this.selectedTemplate.origins=JSON.stringify(this.selectedTemplate.originsArray);
    this.templatesService.save(this.selectedTemplate,(id)=>{
      this.getItems();
      if(oncomplete)
        oncomplete();
    });
  }


  addOrigin():void{
    let c:Origin={} as Origin;
    if(this.selectedTemplate.originsArray==undefined)
      this.selectedTemplate.originsArray=[];

    this.selectedTemplate.originsArray.push(c);
  }

  removeOrigin(c:Origin):void{
    for(let i=0;i<this.selectedTemplate.originsArray.length;i++)
      if(this.selectedTemplate.originsArray[i]==c)
        this.selectedTemplate.originsArray.splice(i,1);
  }

  getExample(c:Origin){
      let args=prompt("Inserire i parametri necessari per ottenere un esempio di origine");
      if(args){
        Globals.ws.requestUrl(c.table,c.task,[args]).subscribe((result)=>{
          this.example=result;
        });

      }
  }

  getPreview(){
    let args=prompt("Inserire i parametri necessari per ottenere un anteprima di esempio");
      if(args){
        this.confirmRule(()=>{
          let m={};
          m['where']=args;
          Globals.setLoading(true);
          this.templatesService.openTemplate(this.selectedTemplate.id,JSON.stringify(m)).subscribe((url)=>{
            Globals.setLoading(false);
            window.open(url,"_blank");
          });
        });
        
      }

  }
  
  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }


}
