import { NgModule } from '@angular/core';
import { CustomfieldsComponent } from './customfields.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxTagsInputModule } from 'ngx-tags-input';
import { FieldsModule } from 'projects/c1-backend/src/app/modules/fields/fields.module';


@NgModule({
   declarations: [CustomfieldsComponent],
   imports:[CommonModule,FormsModule,NgxTagsInputModule],
   exports: [CustomfieldsComponent]   
})
export class CustomfieldsModule{ 

}
