import { EventEmitter, NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { AdvancedsearchModule } from '../../modules/advancedsearch/advancedsearch.module';
import { InventoriesRoutingModule } from './inventories-routing.module';
import { ContactModule } from '../../modules/contact/contact.module';
import { InventoriesComponent } from './inventories.component';
import { InventoriesDetailsComponent } from './inventories-details/inventories-details.component';
import { InventoryoutComponent } from './inventoryout/inventoryout.component';
import { ListinventoriesComponent } from './listinventories/listinventories.component';
import { LoadserialsComponent } from './loadserials/loadserials.component';
import { LoginventoryComponent } from './loginventory/loginventory.component';
import { LogstockComponent } from './logstock/logstock.component';
import { ShowserialsComponent } from './showserials/showserials.component';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { SearchproductModule } from '../../modules/searchproduct/searchproduct.module';
import { QrcodereaderModule } from 'projects/core/src/common/qrcodereader/qrcodereader.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { ComboboxModule } from 'projects/core/src/common/combobox/combobox.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { TreecategoriesModule } from '../../modules/treecategories/treecategories.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { ProductitemModule } from '../../modules/productitem/productitem.module';
import { SalesComponent } from './sales/sales.component';
import { MethodpaymentComponent } from './methodpayment/methodpayment.component';
import { GoodsModule } from '../../modules/goods/goods.module';
import { DocumentsModule } from '../documents/documents.module';
import { LoadComponent } from './load/load.component';
import { MoveinventoryModule } from '../../modules/moveinventory/moveinventory.module';
import { StateinventoriesComponent } from './stateinventories/stateinventories.component';
import { ReferenceModule } from '../../modules/reference/reference.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { HistoryComponent } from './history/history.component';
import { HistorystockComponent } from './historystock/historystock.component';
import { ChartsModule } from 'ng2-charts';
import { ReceiptModule } from '../../modules/receipt/receipt.module';
import { KeyboardModule } from '../../modules/keyboard/keyboard.module';





@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowlistModule,
        WindowModule,
        CitiesModule,
        PaginationModule,
        AdvancedsearchModule,
        InventoriesRoutingModule,
        ContactModule,
        ModalModule,
        SelectfieldcustomModule,
        QrcodereaderModule,
        ToolbarmodelModule,
        ComboboxModule,
        TreecategoriesModule,
        FilterdateModule,
        GoodsModule,
        SearchproductModule,
        ProductitemModule,
        DocumentsModule,
        SelectfieldcustomModule,
        ReferenceModule,
        ChartsModule,
        ReceiptModule,
        KeyboardModule
       

    ],
   exports: [InventoriesComponent,InventoriesDetailsComponent,LoadserialsComponent,SalesComponent,LoginventoryComponent,MoveinventoryModule,HistoryComponent],
   declarations: [InventoriesComponent,InventoriesDetailsComponent,InventoryoutComponent,ListinventoriesComponent,LoadserialsComponent,LoginventoryComponent,LogstockComponent,ShowserialsComponent,SalesComponent,MethodpaymentComponent, LoadComponent, StateinventoriesComponent, HistoryComponent, HistorystockComponent],
   providers: [],
})
export class InventoriesModule{ 

    static savingInventoriesFastEvent:EventEmitter<void>=new EventEmitter();


}