import { Component, OnInit ,Output,EventEmitter, AfterViewInit, Input} from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { ModelList } from 'projects/core/src/include/modellist';
import { FormBuilder } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { Deadlines } from '../../../db/deadlines';
import { DeadlinesService } from '../../../services/deadlines.service';
import { DeadlineDetailsComponent } from '../deadline-details/deadline-details.component';
import { Subject } from 'rxjs';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { ListComponent } from '../list/list.component';
registerLocaleData(localeIt, 'it');
@Component({
  selector: 'app-deadlines-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent extends ModelList<Deadlines> implements OnInit {

  @Output()
  periodSelected:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  dateSelected:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  noEvent:EventEmitter<any>=new EventEmitter<any>();

  @Input()
  list=[];

  locale: string = "it";
  today=new Date();
  viewDate: Date = new Date();
  
  date_selected=new Date();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];



  constructor(
    private deadlinesService: DeadlinesService,
    private fb1: FormBuilder,

  ) { 
    super(deadlinesService,fb1);
    this.detailViewModal=DeadlineDetailsComponent;
  }

  ngOnInit(): void {
    this.handleEvent(this.today);

    
  }

  ngOnChanges(){
    this.events=[];
      for(let d of this.list){


          //verifica se la tipologia di spesa è stata già segnata
          let found=null;
          for(let i=0;i<this.events.length;i++){
            if(this.events[i].meta.date==d.date && this.events[i].meta.id_type==d.id_type){
              found=this.events[i];
            }
          }

          if(found){
            found.meta.count=found.meta.count+1;
            found.meta.total=found.meta.total+d.amount*(1+d.tax/100);
            found.meta.list.push(d);
          }else{
            let c:CalendarEvent={} as CalendarEvent;
            c.start=new Date(d.date);
            c.title=d.subject;    
            
            c.meta=d;
            c.meta.count=1;
            c.meta.total=d.amount*(1+d.tax/100);
            c.meta.list=[];
            c.meta.list.push(d);
            this.events.push(c);
          
          }

        
        
        
  
      }
  }


  dayClicked(d:any): void {
    this.date_selected=d.date;

    //crea la lista da mostrare
    let list=[];
    for(let e of d.events){
      for(let l of e.meta.list){
        list.push(l);
      }
    }

    Globals.modal.showModal(ListComponent,[
      {"name":"list","value":list}
    ],()=>{

    });


  }


  handleEvent(d:any) {

    
  
    let date_from=new Date(d.getFullYear(), d.getMonth(), 1); 
    let date_to=new Date(d.getFullYear(), d.getMonth()+1, 0); 

    


    this.periodSelected.emit({"date_from":Helper.convertDateControl(date_from),"date_to":Helper.convertDateControl(date_to)});
     
     
   }



}
