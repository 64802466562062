import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Modal } from 'projects/core/src/include/modal';
import { Extension } from 'projects/core/src/include/parameters';
import { Inventories, InventoriesDetails, InventoryMode, SNAvaible } from '../../db/inventories';
import {Product, stock } from '../../db/product';
import { InventoriesService } from '../../services/inventories.service';
import { InventoriesDetailsService } from '../../services/inventoriesdetails.service';

import { ProductsService } from '../../services/products.service';
@Component({
  selector: 'app-moveinventory',
  templateUrl: './moveinventory.component.html',
  styleUrls: ['./moveinventory.component.sass']
})
export class MoveinventoryComponent extends Modal implements OnInit {

  @Input()
  createDDT:boolean=false;

  id_product:number;
  id_inventory:number;
  stocks:stock[]=[];
  stocks_orginal:stock[]=[];
  total_original:number=0;
  total:number=0;
  product:Product;
  module:Extension;
  force_unload_default_stock:boolean=false;
  default_stock:stock;
  default_stock_initial:number;
  
  selectedSerial:any=[];

  get not_assigned():number{

    this.total=0;
    this.stocks.forEach(element => {
      this.total=this.total+element.stock;
    });

    return (this.total_original-this.total);

  }





  quantity:number;

  max_quantity:number;

  serials:SNAvaible[]=[];

  constructor(
    private inventoriesService:InventoriesService,
    private inventoriesDetailService:InventoriesDetailsService,
    private productsService:ProductsService
    ) {super() }

  list:Inventories[];

  ngOnInit(): void {
    this.getInventories();
    this.getItem();

    this.module=Globals.parameters.get("inventories");
    this.force_unload_default_stock=this.module.getParam("force_unload_default_stock",false);
    
  }


  getItem(){
    this.productsService.getItem(this.id_product).subscribe((item)=>{
      this.product=item;
      this.stocks=item.stocks;

      //trova il magazzino di default
      this.stocks.forEach(val=>{
        let id_inventory_default=Globals.parameters.get("inventories").getParam("default_inventory",0);
        if(val.id_inventory== id_inventory_default){
            this.default_stock=val;
            this.default_stock_initial=val.stock;

        }
        if(!val.serials)
          val.serials=[];

        if(!val.id_product)
          val.id_product=this.id_product;
        if(!val.stock)
          val.stock=0;

      });

      //crea copia del magazzino
      this.stocks.forEach(val => this.stocks_orginal.push(Object.assign({}, val)));
      
      //calcola il totale delle quantità di tutti i magazzini
      this.stocks_orginal.forEach(element => {
        this.total_original=this.total_original+element.stock;
      });


    });
  }


  confirm(){
    //verifica se si sono assegnate quantità inferiori rispetto a quanto è il totale di partenza
    if(this.total>this.total_original){
      if(confirm("Il numero di quantità impostate supera quelle attualmente disponibili. Procedere con l'inserimento di voci di carico?")){

      }else{
        return;
      }
    }

    
    let movement:InventoriesDetails[]=[];
    
    if(this.product.has_serials){
      //effettua tutti gli scarichi
      for(let i=0;i<this.stocks.length;i++){
          //prendi tutti i seriali che non sono più disponibili
          let serialsToUnload=[];
          if(this.stocks_orginal[i].serials && this.stocks_orginal[i].serials.length>0)
            serialsToUnload=this.stocks_orginal[i].serials.filter(x=>!this.stocks[i].serials.includes(x));
          serialsToUnload.forEach(element => {
            let unload:InventoriesDetails=new InventoriesDetails();
            unload.id_product=this.id_product;
            unload.description=this.product.brand+" "+this.product.name;
            unload.id_inventory=this.stocks[i].id_inventory;
            unload.quantity=1;
            unload.netprice=this.product.netcost;
            unload.tax=this.product.taxcost;
            unload.discount1=this.product.discountcost1;
            unload.discount2=this.product.discountcost2;
            unload.discount3=this.product.discountcost3;
            
            unload.sn=element.sn;
            unload.type=InventoryMode.unload;
            unload.id_user=Globals.user.id;
            unload.note="Rettifica manuale";
            movement.push(unload);
          });

        

      }

      //effettua tutti i carichi
      for(let i=0;i<this.stocks.length;i++){
        //prendi tutti i seriali che non sono più disponibili
        let serialsToload=[];
        if(this.stocks[i].serials && this.stocks_orginal[i].serials)
          serialsToload=this.stocks[i].serials.filter(x=>!this.stocks_orginal[i].serials.includes(x));
        serialsToload.forEach(element => {
        

          let load:InventoriesDetails=new InventoriesDetails();
          load.id_product=this.id_product;
          load.description=this.product.brand+" "+this.product.name;
          load.id_inventory=this.stocks[i].id_inventory;
          load.quantity=1;
          load.sn=element.sn;
          load.type=InventoryMode.load;
          load.id_user=Globals.user.id;
          load.netprice=this.product.netcost;
          load.tax=this.product.taxcost;
          load.discount1=this.product.discountcost1;
          load.discount2=this.product.discountcost2;
          load.discount3=this.product.discountcost3;
          load.note="Rettifica manuale";
          movement.push(load);
        });
        
      }

      

    }else{

      //effettua tutti gli scarichi
      for(let i=0;i<this.stocks.length;i++){
        //verifica se bisogna effettuare uno scarico
        if(this.stocks[i].stock<this.stocks_orginal[i].stock){
          let unload:InventoriesDetails=new InventoriesDetails();
          unload.id_product=this.id_product;
          unload.description=this.product.brand+" "+this.product.name;
          unload.id_inventory=this.stocks[i].id_inventory;
          unload.quantity=this.stocks_orginal[i].stock-this.stocks[i].stock;
          unload.type=InventoryMode.unload;
          unload.id_user=Globals.user.id;
          unload.netprice=this.product.netcost;
          unload.tax=this.product.taxcost;
          unload.discount1=this.product.discountcost1;
          unload.discount2=this.product.discountcost2;
          unload.discount3=this.product.discountcost3;
          unload.note="Rettifica manuale";
          movement.push(unload);
        }
      }

      //effettua tutti i carichi
      for(let i=0;i<this.stocks.length;i++){
        //verifica se bisogna effettuare uno scarico
        if(this.stocks[i].stock>this.stocks_orginal[i].stock){
          let load:InventoriesDetails=new InventoriesDetails();
          load.id_product=this.id_product;
          load.description=this.product.brand+" "+this.product.name;
          load.id_inventory=this.stocks[i].id_inventory;
          load.quantity=this.stocks[i].stock-this.stocks_orginal[i].stock;
          load.type=InventoryMode.load;
          load.id_user=Globals.user.id;
          load.netprice=this.product.netcost;
          load.tax=this.product.taxcost;
          load.discount1=this.product.discountcost1;
          load.discount2=this.product.discountcost2;
          load.discount3=this.product.discountcost3;
          load.note="Rettifica manuale";
          movement.push(load);
        }
      }
    }

  //unisci i due set di operazioni
  this.inventoriesDetailService.saveMultiple(movement,()=>{
    this['modalWindow'].close("success");
  });

  }



  getInventories(){
    this.inventoriesService.getInventories().subscribe((items)=>{
      this.list=items;
      if(this.list.length>0)
        this.id_inventory=this.list[0].id;
    });
  }


  moveSerials(id_inventory){
    let serialsToMove=[];
    
    //prendi tutti i selezionati
    for(let s of this.stocks){
      if(s.serials){
        for(let i=0;i<s.serials.length;i++){
          if(s.serials[i].selected){
            serialsToMove.push(s.serials[i]);
            
          }
        }
        s.serials=s.serials.filter(x=>(x.selected==false || x.selected==null));
      }else{
        s.serials=[];
      }
      s.stock=this.getAvaible(s);
      

    }



   

    //attribuisci i seriali nel  magazzino di destinazione
    for(let s of this.stocks){
      if(s.id_inventory==id_inventory){
        for(let ser of serialsToMove){
          ser.selected=false;
          s.serials.push(ser);
        }
        s.stock=this.getAvaible(s);
       

      }
    }


  }

  getAvaible(stock:stock){
    if(stock.serials)
      return stock.serials.filter(x=>x.avaible>0).length;
    else
      return 0;
  }

  deltaQuantity(stock:stock,delta=1){

    delta=parseInt(delta.toString());
    stock.stock=stock.stock+delta;
    if(stock.id_inventory!=this.default_stock.id_inventory)
      if(this.default_stock.stock>0 && !this.force_unload_default_stock)
        this.default_stock.stock=this.default_stock.stock+delta*(-1);


  }


  changeQuantity(stock:stock,value){
    value=parseInt(value.toString());
   
    if(stock.id_inventory!=this.default_stock.id_inventory)
      if( !this.force_unload_default_stock)
        this.default_stock.stock=this.default_stock_initial+value*(-1);


  }

  updateStock(){

  }

  cancel(){
    this['modalWindow'].close("cancel");
  }

  selectSn(inventory,event){
    console.log(event);
    for(let e of event){
      for(let s of inventory.serials){
        if(s.sn==e){
          s['selected']=true;
        }
      }
    }


  }

  confirmDDT(){
    
  }


}
