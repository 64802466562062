import { NumberSymbol } from "@angular/common";
import { Image } from "projects/c1-backend/src/app/db/image";
import { Shops } from "projects/c1-backend/src/app/db/shops";

export class Calendar{
    id:number;
    date:string;
    time_start:string;
    time_end:string;
    state:number;
    status:number;
    price_adult:number;
    price_children:number;
    id_tour:number;
    quantity:number;
    min_quantity:number;
    location_start:string;
    id_shop_start:number;
    shop_start_name:string;
    shop:Shops;
    bookable:boolean;
    prices:any;
    adults:number;
    children:number;

    

    constructor(){
        this.min_quantity=1;
        
    }

    get partecipants(){
        return this.adults+this.children;
    }

    parsePrices(){
        try{
            this.prices=JSON.parse(this.prices);
        }catch{
            this.prices=[];
        }
    }

    getPrice(){
        //trova il valore di prezzo che si allinea al numero di partecipanti

        let partecipants=this.adults+this.children;
        if(this.prices!=null){
            for(let p of this.prices){
                if(partecipants>=p.from && partecipants<=p.to){
                    this.price_adult=p.price_adult;
                    this.price_children=p.price_children;
                    return;
                }
            }
        }


    }

   
}


export class Tour{
    id:number;
    code:string;
    title:string;
    subtitle:string;
    description:string;
    intro:string;
    images:Image[];
    state:number;
    status:number;
    dates:Calendar[];
    price:number;
    duration:number;
    features:any;
    id_shop_start:number;
    shop:Shops;
    details:any[];
    type:number;
    

    constructor(){
        this.details=[];
        this.dates=[];
        
    }

    parseFeatures(){
        let features=[];
        try{
            features=JSON.parse(this.features.toString());
        }catch{
            features=[];
        }
        this.features=features;
    }

    parseImages(){
        let images=[];
        try{
            images=JSON.parse(this.images.toString());
        }catch{
            images=[];
        }
        this.images=[];
        for(let i of images){
            let img=new Image();
            Object.assign(img,i);
            this.images.push(img);
        }


    }

    coverImage(){
        if(this.images.length>0){
            return this.images[0];
        }else{
            return new Image();
        }
    }

    

}