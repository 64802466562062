import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Contact } from 'projects/c1-backend/src/app/db/contact';
import { Type } from 'projects/c1-backend/src/app/db/type';
import { TypesService } from 'projects/c1-backend/src/app/services/types.service';
import { Helper } from '../../helper';
import { NotificationMessages } from '../../include/structures';
@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.sass']
})
export class GatewayComponent implements OnInit {

  constructor(private typesService: TypesService) { }
  public Editor = ClassicEditor;

  @Input()
  gateway:NotificationMessages;

  @Input()
  contact:string;

  @Input()
  contacts:Contact[];

  @Input()
  record:any;

  @Input()
  isConfig:boolean=false;

  @Output()
  OnFieldsChanged:EventEmitter<any>=new EventEmitter();

  type_contacts:Type[];
  other_contact:boolean=false;
  email_from:string;
  email_cc:string;
  email_subject:string;
  email_body:string;
  email_attachment:string;

  sms_body:string;
  whatsapp_body:string;



  ngOnInit(): void {
    this.typesService.getTypes("contacts").subscribe((items)=>{
      this.type_contacts=items;
    });

    if(this.gateway==undefined)
      this.gateway={} as NotificationMessages;

   this.gateway['enable']=1;
    
  }

  ngOnChanges():void{

    

    let obj:Object={} as Object;
    switch (this.gateway.gateway){
      case "email":
        
        try{
          obj=JSON.parse(this.gateway.message);
          this.email_from=obj['email_from'];
          this.email_cc=obj['email_cc'];
          this.email_subject=Helper.replaceKeyword(obj['email_subject'],this.record);
          this.email_body=obj['email_body'];//Helper.replaceKeyword(obj['email_body'],this.record); 
          
          //this.email_body=this.isConfig?obj['email_body']:this.gateway.value;//Helper.replaceKeyword(obj['email_body'],this.record); 
          
          this.email_attachment=obj['email_attachment'];
          this.ParseFields();
        
        }catch{
          this.email_from="";
          this.email_cc="";
          this.email_subject="";
          this.email_body="";
          
          }
        break;
      case "sms":
        try{
          obj=JSON.parse(this.gateway.message);
          this.sms_body=this.isConfig?obj['body']:this.gateway.value;//Helper.replaceKeyword(obj['body'],this.record);
          this.ParseFields();
        
        }catch{
          this.sms_body="";
        }
        break;
      case "whatsapp":
        try{
          obj=JSON.parse(this.gateway.message);
          this.whatsapp_body=Helper.replaceKeyword(obj['body'],this.record);
          this.ParseFields();
        
        }catch{
          this.whatsapp_body="";
        }
        break;
      }


     
  }


  ParseFields(){
    let obj:Object={} as Object;
        
    switch (this.gateway.gateway){
      case "email":
        obj['email_from']=this.email_from;
        obj['email_cc']=this.email_cc;
        obj['email_subject']=this.email_subject;
        obj['email_body']=this.email_body;
        obj['email_attachment']=this.email_attachment;
        break;
      case "sms":
        obj['body']=this.sms_body;
        break;
      case "whatsapp":
        obj['body']=this.whatsapp_body;
        break;
    }

    //this.OnFieldsChanged.emit(obj);

    this.gateway.message=JSON.stringify(obj);
    
  }


  onSelectContact(contact:any){
    this.other_contact=false;
 
    if(contact.target.value==""){
      this.other_contact=true;
      this.gateway['other_contact']=true;
    }else{
      this.gateway['other_contact']=false;
      
    }
  }

}
