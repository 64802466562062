import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FinancialAccountsService } from '../../services/financialaccounts.service';

@Component({
  selector: 'app-financialaccount',
  templateUrl: './financialaccount.component.html',
  styleUrls: ['./financialaccount.component.css']
})
export class FinancialaccountComponent implements OnInit {

  @ViewChild("modalTree")
  modalTree:TemplateRef<any>;
  modalTreeRef;

  @Input()
  id_financialaccount:number;

  @Input()
  financialaccount:string;


  @Input()
  showOnlyTree=false;

  @Input()
  showAll=false;

  @Input()
  classname="form-control form-control-sm"

  @Output()
  selected:EventEmitter<any>=new EventEmitter<number>();

  financialaccounts=[];
  options = {};
  openTree=false;
  nodeSelected;
  

  constructor(
    private financialAccountsService:FinancialAccountsService,
    private modalService:NgbModal

  ) { }

  ngOnInit(): void {
    this.financialAccountsService.getAccounts().subscribe((items_c)=>{
      this.financialaccounts=items_c;

      if(this.showOnlyTree){
        this.open();
      }

      if(this.showAll){
        let a=[];
        a['id']="";
        a['name']="Tutti i conti contabili";
        a['children']=[];
        this.financialaccounts.unshift(a);
      } else {
        let a=[];
        a['id']="";
        a['name']="Nessun conto contabile";
        a['children']=[];
        this.financialaccounts.push(a);
      }

    });

    

  }

  ngOnChanges(){
    if(this.id_financialaccount){
      let n=this.searchNode(this.financialaccounts,this.id_financialaccount);
      if(n){
        this.financialaccount=n.name;
        console.log(n);
    
      }
    }

    
    
    
  }

  selectNode($event){
    //if($event.node.data.children.length==0){
      this.financialaccount=$event.node.data.name;
      this.nodeSelected=$event.node.data;
      
    //}
  }

  


  searchNode(nodes,id){
    let n_find=null;
    for(let n of nodes){
      if(n['id']==id){
        n_find=n;
      }
      if(n['children'].length>0){
        n_find=this.searchNode(n['children'],id);
      }

      if(n_find){
        return n_find;
      }
      
    }

    return n_find;
  }


  open(){
    this.modalTreeRef=this.modalService.open(this.modalTree);
  }

  close(){
    if(this.nodeSelected)
      this.selected.emit({"id":this.nodeSelected.id,"name":this.nodeSelected.name});
    this.modalTreeRef.close("success");
  }
}
