import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelecttemplateComponent } from './selecttemplate.component';
import { ModalModule } from '../modal/modal.module';



@NgModule({
  declarations: [SelecttemplateComponent],
  exports:[SelecttemplateComponent],
  imports: [
    CommonModule,
    ModalModule
  ]
})
export class SelecttemplateModule { }
