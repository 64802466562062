import {Component, Inject, LOCALE_ID } from '@angular/core';
import { LoginComponent } from 'projects/core/src/common/login/login.component';
import { Globals } from 'projects/core/src/globals';
import { User } from 'projects/core/src/include/structures';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  

  get title(){
    return Globals.title;
  }

  get currentIsLogin():boolean{
    return Globals.isLogin;
  }

  get currentUser():User{
    return Globals.user;
  }

  get loading():boolean{
    return Globals.loading;
  }

  get config():any{
    return Globals.config;
  }

  get company():any{
    return Globals.config.company;
  }

  get history():any{
    return Globals.navigation.history;
  }

  get c1connector_status():any{
    return Globals.c1connector.connected;
  }
  get c1connector_c1connectorid():any{
    return Globals.c1connector.c1connectorid;
  }

 
  menu=[]; //menu nella sidebar
  menu_settings=[]; //menu nella sidebar




  constructor(public globals:Globals,
    @Inject(LOCALE_ID) public locale: string
    ){
   
    Globals.config.loadConfig(()=>{
      
      Globals.navigation.skipLocationChange=true;
      Globals.access.loginPage="/login";
     

      
      let params=Globals.navigation.getQueryString();
      
      let username:string=Globals.navigation.getParam(params,"user",localStorage.getItem(Globals.access.prefix+"_username"));
      let password:string=Globals.navigation.getParam(params,"pwd",localStorage.getItem(Globals.access.prefix+"_password"));

      
      Globals.access.loginEvent.subscribe((result)=>{
        if(result){
          this.createMenu();
          Globals.c1connector.connectMqtt();
          
        }

        Globals.navigation.onNavigate(["/booking"]);
      });
      


      Globals.access.logoutEvent.subscribe((result)=>{
        this.openLogin();
      });
      
      Globals.parameters.loadModulesEvent.subscribe((result)=>{
        this.createMenu();
      });

       //auto login in fase di startup
       if((username!="" && username!=null && username!=undefined) && (password!="" && password!=null && password!=undefined)){
        Globals.access.login(username,password,(success)=>{
          if(!success){
            this.openLogin();
          }
        },false);
      }else{
        this.openLogin();
      }
      
    });
    
    

  }
  
  openLogin(){
    Globals.modal.showModal(LoginComponent,[
      {"name":"mode","value":"modal"},
      
    ],(instance)=>{
      if(instance){

      }
    },"sm");
  }

  createMenu(){
    this.menu=[];
    this.menu_settings=[];
    
    for(let m of Globals.parameters.modules){
      if(m.permissions.includes(this.currentUser.role) && m.status==1){
        if(m.label!=""){
          if(m.type==4 || m.type==3)
            this.menu.push(m);
          if(m.type==2)
            this.menu_settings.push(m);
        }
      }
        
    }
  }

  logout(){
    Globals.access.logout();
  }

  goToView(item){
    Globals.navigation.deleteLastHistory();
   

    Globals.navigation.onNavigate([item.url]);
    
 } 



}
