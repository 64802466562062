<app-window [title]="title">
    <form role="form" id="detailForm">
        <div class="form-group row mt-2">
            <div class="col-2"><label>Nome</label></div>
            <div class="col-4">
                <input type="text" class="form-control form-control-sm" name="name" [(ngModel)]="record.name">
            </div>

            <div class="col-2"><label>Tabella</label></div>
            <div class="col-4">
                <app-selecttables [name]="'table'" [value]="record.table" [show_all]="false" (onchange)="record.table=$event;"></app-selecttables>
                 
            </div>
        </div>
        <div class="form-group row mt-2">
            <div class="col-2"><label>Titolo</label></div>
            <div class="col-4">
                <input type="text" class="form-control form-control-sm" name="title" [(ngModel)]="record.title">
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col-2"><label>Abilitato</label></div>
            <div class="col-4">
                <select class="form-control form-control-sm" name="enabled" [(ngModel)]="record.enabled">
                    <option value="0">Non abilitata</option>
                    <option value="1">Abilitata</option>
                </select>
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col-2"><label>Script (PHP)</label></div>
            <div class="col-10">
                <textarea class="form-control form-control-sm" rows="30" name="script" [(ngModel)]="record.script"></textarea>
                  
            </div>
        </div>

            
    </form>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>

</app-window>