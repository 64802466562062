<app-modal title="Seleziona il modello" [showFooter]="false" class="modal-xl">
    
    <div class="m-4" *ngIf="templates.length==0">
        <h5 class="text-info"><i class="icon fas fa-warning mr-2"></i>Nota:</h5>
        <i>Nessun modello di esportazione trovato!</i>
    </div>

    <div *ngFor="let n of templates" >
        <button type="button" class="btn btn-default btn-block btn-light text-left mb-1" (click)="getTemplate(n)">
                <i data-toggle="tooltip" title="{{n.format}} file" class="mr-2 fa-2x fas fa-file fa-file-{{n.format}}" 
                    [class.fa-file-code]="n.format=='htm' || n.format=='html' || n.format=='xml'"
                    [class.text-teal]="n.format=='csv'"
                    [class.text-maroon]="n.format=='pdf'">
                    </i>&nbsp;
                <span class="d-inline-block text-truncate" style="width:80%">{{n.name}}</span>
            <small class="text-gray text-uppercase float-right">{{n.format}}</small>
        </button>
    </div>
            
</app-modal>