import { Component, OnInit,Input } from '@angular/core';
import {ModelList} from 'projects/core/src/include/modellist'
import {ContactsService} from '../../services/contacts.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import {TypesService} from '../../services/types.service';

import { ActivatedRoute } from '@angular/router';
import {Contact} from "../../db/contact";
import {Type} from '../../db/type';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent extends ModelList<Contact> implements OnInit {

  id:number;

  @Input()
  table:string;
  @Input()
  list:Contact[];

  mode="embedded";

  type_contacts:Type[];

  constructor(private route: ActivatedRoute,
    private contactsService: ContactsService,
    private fb1: FormBuilder,
    private typesService: TypesService,
    ) {super(contactsService,fb1); }

  

  ngOnInit(): void {

    this.typesService.getTypes("contacts").subscribe((items)=>{
      this.type_contacts=items;
    });
    
  }


  newRecord():void{
    let r:Contact=new Contact();
    r.id_type=this.type_contacts[0].id;
    
    this.list.push(r);
  }

  deleteRecord(c:Contact):void{
    if(confirm("Sicuro di voler eliminare la voce di contatto selezionata?")){
   
      const index: number = this.list.indexOf(c);
      this.list.splice(index,1);
    }
  }




}
