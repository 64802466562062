import { Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { Model } from '../../include/model';

@Component({
  selector: 'app-toolbarmodel',
  templateUrl: './toolbarmodel.component.html',
  styleUrls: ['./toolbarmodel.component.css']
})
export class ToolbarmodelComponent implements OnInit,OnChanges {


  @HostListener('window:keydown', ['$event'])
    onKeyDown(event) {
      if(event.keyCode==119){ //F8
        this.save();
      }

      
    }

  @Input()
  model:Model<any>;

  @Input()
  showSave=true;

  @Input()
  showSaveAndClose=true;

  @Input()
  showSaveAndNew=true;

  @Input()
  showSaveAndPrint=true;
  
  get id(){
    return this.model.id;
  }
  constructor() { }

  ngOnInit(): void {
    if(this.model.mode=="modal"){
      this.showSaveAndClose=false;
      this.showSaveAndNew=false;
    }
  }

  ngOnChanges():void{
    if(this.model.templates!=null && this.model.templates.length>0){
      this.showSaveAndPrint=true;
    }else{
      this.showSaveAndPrint=false;
    }
  }

  get field_required(){
    return this.model.field_required;
  }

  get disable(){
    return this.model.disable();
  }
  onBack(){
    //se in modalità modal chiudi la finestra
    
      this.model.onBack();
    
  }

  saveAndNew(){
    this.model.saveAndNew();
  }
  saveAndClose(){
    this.model.saveAndClose();
  }
  save(){
    if(this.model.mode=="modal")
      this.model.saveAndClose();
    else
      this.model.save();
  }


  saveAndPrint(){
    this.model.afterSave=()=>{
      this.model.export();
    };

    this.save();


  }

}
