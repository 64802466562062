import { Injectable } from '@angular/core';
import {Inventories} from '../db/inventories';

import {WSService} from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import {Globals} from 'projects/core/src/globals';


@Injectable({
  providedIn: 'root'
})
export class InventoriesService extends WSService<Inventories> {

  constructor() {super("inventories") }

  getInventories():Observable<Inventories[]>{
    return Globals.ws.requestUrl<Inventories[]>("inventories","getItems",[],true,0,100000);
  }

  resetStock(id_product,id_inventory,sn):Observable<any>{
    return Globals.ws.requestUrl<any>("inventories","resetStock",[id_product,id_inventory,sn]);
  }

}

