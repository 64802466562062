
<app-modal [title]="'Ricerca coordinate'">
        <div class="row">
                <div class="col-12">
                    <div class="input-group">
                        <input type="text" class="form-control" [readonly]="loading" [(ngModel)]="search" placeholder="Inserisci l'indirizzo da ricerca..." (key.enter)="geocoding()">
                        <div class="input-group-append">
                            <button class="btn btn-default" (click)="geocoding()">Cerca</button>
                            <button class="btn btn-default" (click)="getCoordinate()"><i class="fas fa-street-view"></i></button>
                        </div>
                    </div>
                    
        
                </div>
                    <small class="alert alert-warning" *ngIf="loading">ricerca indirizzo in corso...</small>
                <div class="col-12 mt-2">
                    <div id="map_container" style="width: 100%;height: 70vh;"></div>
                </div>
        </div>

        <div class="row">
                <div class="col-12">
                    <small>{{latitude}},{{longitude}}</small>
                </div>
                
        </div>
</app-modal>