<app-windowlist [model]="this">
    <div class="card">
        <div class="row">

            <div class="col-md-6">
                <div class="input-group input-group-sm p-1">
                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                    <div class="input-group-append ">
                        <button type="submit" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                        <button class="btn btn-default text-danger" data-toggle="tooltip" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <app-selecttables [name]="'filter_type'" [value]="filter_type.value" [show_all]="true" (onchange)="filter_type.value=$event;getItems()"></app-selecttables>
            </div>
            <div class="col-lg-4">
                <button class="btn btn-success btn-sm float-right m-1" data-toggle="modal" data-target="#modalRule" (click)="addType()"><i class="fa fa-plus"></i></button>
            </div>
        </div>
        <div class="card-body p-0">
          <form>
            <table class="table table-sm table-sriped text-nowrap m-0" >
                <thead>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Tabella</th>
                    <th></th>
                </thead>
                <tbody>

                <!-- elemento ripetuto -->
                <tr *ngFor="let types of list">
                    <td>{{types.id}}</td>
                    <td class="pl-2">{{types.name}}</td>
                        <td><small><i>{{types.table}}</i></small></td>
                    <td class="pr-2">
                        <a type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-sm" (click)="selectRecord(types);">
                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                        </a>
                        <button   *ngIf="mode!='modal'" type="button" data-toggle="tooltip" title="Elimina" class="btn btn-danger btn-xs float-right ml-1"  (click)="confirm_delete(types.id)">
                            <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        <button   *ngIf="mode!='modal'" type="button" data-toggle="tooltip" title="Duplica" class="btn btn-info btn-xs float-right ml-1" (click)="duplicate(types)">
                            <i class="far fa-clone"></i><span class="d-none d-sm-inline-block">&ensp;</span></button> 
                        <button   *ngIf="mode!='modal'" type="button" data-toggle="tooltip" title="Modifica" class="btn btn-secondary btn-xs float-right ml-1"  data-toggle="modal" data-target="#modalRule" (click)="setRule(types)">
                            <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                    </td>
                </tr>

                </tbody>
            </table>
          </form>
        </div>
        <div class="card-footer clearfix py-1">
            <app-pagination [model]="this" ></app-pagination>
        </div>
    </div>
</app-windowlist>

<div class="modal fade" id="modalRule" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header py-1 bg-primary">
                <b>Impostazione record</b>
            </div>
            <div class="modal-body" *ngIf="selectedType">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Nome</label>
                            <input class="form-control form-control-sm" type="text" name="name" [(ngModel)]="selectedType.name"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Tabella</label>
                            <app-selecttables [name]="'table'" [value]="selectedType.table" [show_all]="false" (onchange)="selectedType.table=$event;"></app-selecttables>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group form-group-sm">
                            <label>Parametri</label>
                            <input class="form-control form-control-sm" type="text" name="params" [(ngModel)]="selectedType.params"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmRule()"><i class="fas fa-save mr-1"></i> Conferma</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"  >Annulla</button>
            </div>
        </div>
    </div>
</div>
