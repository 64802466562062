import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Globals } from '../../globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  uname:string="";
  passwd:string="";

  remember_connected:boolean=false;

  @Input()
  redirectAfterLoginEvent:boolean=true;

  @Input()
  redirectAfterLogin:string="";

  @Input()
  useStoredAccess=true;

  @Input()
  redirectToLoginPage=true;

  @Output()
  onSuccess:EventEmitter<boolean>=new EventEmitter<boolean>();

  get softwarename():string {
      return Globals.config.softwarename;
  }

  get softwareicon():string {
    return Globals.config.softwareicon;
}

 get copyright(){
   return Globals.config.copyright;
 }
  constructor() { }

  ngOnInit(): void {
  }

  onLogin(){


    Globals.access.onLogin(this.uname,this.passwd,this.remember_connected?"1":"0",this.redirectAfterLogin,this.useStoredAccess,this.redirectToLoginPage,this.redirectAfterLoginEvent);
  }

  login(){
    Globals.access.login(this.uname,this.passwd,(success)=>{
      if(success){
        this.onSuccess.emit(true);
        this['modalWindow'].close("success");

        if(this.remember_connected){
          localStorage.setItem(Globals.access.prefix+"_username",this.uname);
          localStorage.setItem(Globals.access.prefix+"_password",this.passwd);
        }


      }
    });
  }

}
