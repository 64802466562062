<div>
    <div  #componentHolder ></div>
</div>
<span *ngIf="references.length>0">    
    <ng-container *ngFor="let ref of references">

       <ng-container *ngIf="ref">
           <div class="row">
                <div class="col-12">  <!-- <div [class.col-12]="!showDisconnect" [class.col-10]="showDisconnect"> -->
                    <!-- INSTALLAZIONE -->
                    <div *ngIf="ref.table_reference=='installations'">
                        <small><div class="badge badge-info">Prodotto installato</div></small>
                        <div class="widget-heading">
                            <div class="badge"><a (click)="openReference(ref,'installations')"><i class="fa fa-cash-register"></i></a></div>
                            <strong [class.trashed]="ref.status==2">{{ref.brand}} {{ref.model}} {{ref.sn}}<div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                        </div>
                        <ng-container *ngIf="ref.shop">
                            <div class="widget-heading" *ngIf="ref.shop.addressItem">
                                <div class="badge"><a (click)="openReference(ref.shop.addressItem,'addresses')"  style="cursor:pointer;"><i class="fa fa-user"></i></a></div>
                                <strong [class.trashed]="ref.shop.addressItem.status==2">{{ref.shop.addressItem.name}}
                                    <span *ngIf="ref.shop.addressItem.balance!=0" class="text-right ml-1" [class.text-red]="ref.shop.addressItem.balance < 0" [class.text-success]="ref.shop.addressItem.balance > 0">{{ref.shop.addressItem.balance | currency: "&euro; "}}</span>
                                    <div *ngIf="ref.shop.addressItem.status==2" class="badge badge-warning ml-1">
                                        <i class="fas fa-exclamation-triangle mr-1"></i>cestinato
                                    </div>
                                    <span class="ml-1" *ngIf="ref.shop.type_params!='default'"><small>({{ref.shop.type}})</small></span>
                                </strong>
                            </div>
                            <div class="widget-subheading text-small">
                            <a (click)="openReference(ref.shop,'shops')" style="cursor:pointer;"><i class="fas fa-store mr-1"></i><i>{{ref.shop.name}}</i></a>
                                <small [class.trashed]="ref.shop.status==2"> ({{ref.shop.city | uppercase}}) 
                                    <i *ngIf="ref.shop.latitude>0" class="fas fa-map-marker-alt ml-1" data-toggle="tooltip" title="{{ref.shop.latitude}}° {{ref.shop.longitude}}°"></i>
                                    <span class="badge badge-warning ml-1" *ngIf="ref.shop.latitude==0">NO GPS</span>
                                    <div *ngIf="ref.shop.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-1"></i>cestinato</div>
                                </small>
                                <span class="ml-1" *ngIf="ref.shop.type_params!='default'"><small>({{ref.shop.type}})</small></span>

                            </div>
                        </ng-container>
                        <ng-container *ngIf="ref.contract && ref.contract.id>0">
                            <hr/>
                            <small>
                                <div class="alert alert-info">
                                    <i class="fa-file-signature fas" (click)="openReference(ref.contract,'contracts')"></i> Prodotto con contratto {{ref.contract.type}} "{{ref.contract.description}}" <span class="badge" [class.badge-warning]="verifyDate(ref.contract.end)" [class.badge-danger]="!verifyDate(ref.contract.end)">scadenza {{ref.contract.end | date:"dd-MM-Y"}}</span>
                                </div>
                            </small>
                        </ng-container>
                    </div>
                    <!-- ADDRESSES -->
                    <div *ngIf="ref.table_reference=='addresses'">
                        <small><div class="badge badge-info">Anagrafica</div></small>
                        <div class="widget-heading">
                            <div class="badge"><a (click)="openReference(ref,'addresses')"  style="cursor:pointer;"><i class="fa fa-user"></i></a></div>
                            <strong [class.trashed]="ref.status==2">{{ref.name}} 
                                <span *ngIf="ref.balance!=0" class="text-right text-sm" [class.text-red]="ref.balance < 0" [class.text-success]="ref.balance > 0">{{ref.balance | currency: "&euro; "}}</span>
                                <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div>
                            </strong>
                        </div>
                        <div class="widget-subheading" *ngIf="ref.city">
                            <div class="badge"><i class="fa fa-map-marker-alt"></i></div>
                            <small> {{ref.city | uppercase}}</small>
                        </div>
                    </div>
                    <!-- SHOP -->
                    <div *ngIf="ref.table_reference=='shops'">
                        <small><div class="badge badge-info">Esercizio</div></small>
                        <div class="widget-heading">
                            <div class="badge"><a (click)="openReference(ref,'shops')"  style="cursor:pointer;"><i class="fa fa-store"></i></a></div>
                            <strong [class.trashed]="ref.status==2">{{ref.name}} <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                            <span class="badge badge-warning" *ngIf="ref.latitude==0">NO GPS</span>
                            <span class="badge badge-danger" *ngIf="ref.type_params!='default'"><small> ({{ref.type}})</small></span>

                        </div>
                        <div class="widget-heading" *ngIf="ref.addressItem">
                            <div class="badge"><a (click)="openReference(ref.addressItem,'addresses')"  style="cursor:pointer;"><i class="fa fa-user"></i></a></div>
                            <strong [class.trashed]="ref.addressItem.status==2">{{ref.addressItem.name}} 
                                <span *ngIf="ref.addressItem.balance!=0" class="text-right text-sm" [class.text-red]="ref.addressItem.balance < 0" [class.text-success]="ref.addressItem.balance > 0">{{ref.addressItem.balance | currency: "&euro; "}}</span> 
                                <div *ngIf="ref.addressItem.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                        </div>
                    
                        <div class="widget-subheading" *ngIf="ref.city">
                            <div class="badge"><i class="fa fa-map-marker-alt"></i></div>
                            <small> {{ref.city | uppercase}} <i *ngIf="ref.latitude>0" class="fas fa-map-marker-alt"></i></small>          
                        </div>

                        <ng-container *ngIf="ref.contract && ref.contract.id>0">
                            <hr/>
                            <small>
                                <div class="alert alert-info">
                                    <i class="fa-file-signature fas" (click)="openReference(ref.contract,'contracts')"></i> Prodotto con contratto {{ref.contract.type}} "{{ref.contract.description}}" <span class="badge" [class.badge-warning]="verifyDate(ref.contract.end)" [class.badge-danger]="!verifyDate(ref.contract.end)">scadenza {{ref.contract.end | date:"dd-MM-Y"}}</span>
                                </div>
                            </small>
                        </ng-container>
                    </div>
                    <!-- CONTRACTS -->
                    <div *ngIf="ref.table_reference=='contracts'">
                        <div class="badge badge-info">Contratto</div>
                        <div class="widget-heading">
                            <div class="badge"><a (click)="openReference(ref.shop,'shops')"  style="cursor:pointer;"><i class="fa fa-store"></i></a></div>
                            <b [class.trashed]="ref.shop.status==2">{{ref.shop.name}}
                                <div *ngIf="ref.shop.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-1"></i>cestinato</div>
                            </b>
                            <span class="badge badge-warning" *ngIf="ref.shop.latitude==0">NO GPS</span>
                            <span class="badge" *ngIf="ref.shop.type_params!='default'"><small> ({{ref.shop.type}})</small></span>
                        </div>
                        <div class="widget-subheading" *ngIf="ref.shop.city">
                            <div class="badge"><i class="fa fa-map-marker-alt"></i></div>
                            <small>{{ref.shop.city | uppercase}} <i *ngIf="ref.shop.latitude>0" class="fas fa-map-marker-alt"></i></small>
                        </div>
                    </div>
                    <!-- CONTRACTS INSTALLMENTS -->
                    <div *ngIf="ref.table_reference=='contractsinstallments'">
                        <div class="badge badge-info">Rata contratto</div>
                        <div class="widget-heading">
                            <div class="badge"><a (click)="openReference(ref,'contracts')"  style="cursor:pointer;"><i class="fas fa-file-signature"></i></a></div>
                            <strong [class.trashed]="ref.status==2">Contratto di <i>{{ref.type}} #{{ref.id_contract}}</i> rata n°<i>{{ref.number}}</i> <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                        </div>
                        <div class="widget-subheading">
                            <div class="badge"><a (click)="openReference(ref.shop,'shops')"  style="cursor:pointer;"><i class="fa fa-store"></i></a></div>
                            <small [class.trashed]="ref.shop.status==2">{{ref.shop.name}} <div *ngIf="ref.shop.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></small>
                            <span class="badge badge-warning" *ngIf="ref.shop.latitude==0">NO GPS</span>
                            <span class="badge" *ngIf="ref.shop.type_params!='default'"><small>({{ref.shop.type}})</small></span>
                        </div>
                    </div>
                    <!-- BOOKING -->
                    <div *ngIf="ref.table_reference=='booking'">
                        <small><div class="badge badge-info">Prenotazione</div></small>
                        <div class="widget-heading" (click)="openReference(ref,'booking')" data-toggle="tooltip" title="Vedi prenotazione" style="cursor:pointer;">
                            <i class="fas fa-file-signature mr-1"></i>
                            <i>Prenotazione <b>#{{ref.code}}</b></i>
                            <span *ngIf="ref.status==2" class="badge badge-warning ml-1"><i class="fas fa-exclamation-triangle mr-1"></i>&nbsp;cestinato</span>
                        </div>
                    </div>
                    <!-- DOCUMENTS -->
                    <div *ngIf="ref.table_reference=='documents' && ref.addressItem">
                        <div class="badge badge-info">Documento</div>
                        <div class="widget-heading" style="font-size:80%;">
                            <div (click)="openReference(ref,'documents')" style="cursor:pointer;">
                                <i class="fas fa-file-signature mr-1"></i>
                                <div class="d-inline-block text-truncate" style="max-width:8rem;" [class.trashed]="ref.status==2"><b>{{getTypeDocument(ref.typedocument)}}</b></div>&nbsp;
                                <div class="d-inline-block text-truncate" style="max-width:8rem;">{{ref.addressItem.name}}</div>
                            </div>
                            <i> n. {{ref.reference}} {{ref.referenceprefix}} del {{ref.date | date:"dd/MM/yyyy"}}<br/>{{ref.description}}</i> 
                            &nbsp;
                            <strong>{{ref.total | currency:"&euro; "}}</strong>
                            <div *ngIf="ref.status==2" class="badge badge-warning ml-1"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div>
                        </div>
                    </div>
                    <!-- TICKET -->
                    <div *ngIf="ref.table_reference=='tickets'">
                        <small><div class="badge badge-info">Biglietto</div></small>
                        <div class="widget-heading" (click)="openReference(ref,'tickets')"  style="cursor:pointer;">
                            <div class="badge"><i class="fas fa-file-signature"></i></div>
                            <strong [class.trashed]="ref.status==2">Biglietti  
                                <ng-container *ngFor="let t of ref.tickets">
                                    <i>{{t.port_from}}-{{t.port_to}} {{t.date | date:"d/M/Y"}}</i> 
                                </ng-container>
                                <div *ngIf="ref.status==2" class="badge badge-warning">
                                    <i class="fas fa-exclamation-triangle"></i>
                                    &nbsp;cestinato
                                </div>
                            </strong>
                        </div>
                    </div>
                    <!-- ASSISTANCE -->
                    <div *ngIf="ref.table_reference=='assistances'">
                        <small><div class="badge badge-info">Assistenza</div></small>
                        <div class="widget-heading" (click)="openReference(ref,'assistances')" style="cursor:pointer;">
                            <div class="badge"><i class="fas fa-file-signature"></i></div>
                            <strong [class.trashed]="ref.status==2"><i> num {{ref.code}} del {{ref.date | date:"dd/MM/yyyy"}}</i> <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                        </div>
                    </div>
                    <!-- TASKS -->
                    <div *ngIf="ref.table_reference=='activities'">
                        <small><div class="badge badge-info">Attività</div></small>
                        <div class="widget-heading" (click)="openReference(ref,'activities')"  style="cursor:pointer;">
                            <div class="badge"><i class="fa-tasks fas"></i></div>
                            <strong>{{ref.type}}</strong>
                        </div>
                    </div>
                    <!-- DEADLINES -->
                    <div *ngIf="ref.table_reference=='deadlines'">
                        <small><div class="badge badge-info">Scadenza</div></small>
                        <div class="widget-heading" (click)="openReference(ref,'deadlines')"  style="cursor:pointer;">
                            <div class="badge"><i class="fa-tasks fas"></i></div>
                            <strong>{{ref.type}}</strong>
                        </div>
                    </div>
                    <!-- PROJECTS -->
                    <div *ngIf="ref.table_reference=='project'">
                        <small><div class="badge badge-info">Progetto</div></small>
                        <div class="widget-heading" (click)="openReference(ref,'project')" style="cursor:pointer;">
                            <div class="badge"><i class="fas fa-file-signature"></i></div>
                            <strong [class.trashed]="ref.status==2"><i> {{ref.name}} - {{ref.addressItem.name}}</i> <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                        </div>
                    </div>
                    <!-- ADS -->
                    <div *ngIf="ref.table_reference=='ads'">
                        <small><div class="badge badge-info">Annuncio</div></small>
                        <div class="widget-heading" (click)="openReference(ref,'ads')" style="cursor:pointer;">
                            <div class="badge"><i class="fas fa-file-signature"></i></div>
                            <strong [class.trashed]="ref.status==2"><i> {{ref.code}} - {{ref.title}}</i> <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                        </div>
                    </div>

                    <!-- DOSSIER -->
                    <div *ngIf="ref.table_reference=='dossier'">
                        <small><div class="badge badge-info">Pratica</div></small>
                        <div class="widget-heading" (click)="openReference(ref,'dossier')" style="cursor:pointer;">
                            <div class="badge"><i class="fas fa-file-signature"></i></div>
                            <strong [class.trashed]="ref.status==2"><i> {{ref.code}} - {{ref.addressItem.name}}</i><br/><small>{{ref.title}}</small> <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-2" *ngIf="showDisconnect">
                    <button class="btn btn-danger btn-xs" (click)="removeReference(ref);"><i class="fa fa-unlink"></i></button>
                </div> -->
            </div>
        </ng-container>
        
    </ng-container>
</span>


<div *ngIf="mode=='detail'">
    <div class="" *ngIf="reference==undefined">
        <i class="fa-2x fas fa-info text-info mr-3"></i>
        <i>Nessun elemento selezionato</i>
    </div>
</div>

<div class="my-2" *ngIf="showConnectBtn">
    <div class="dropdown">
        <button class="btn btn-xs btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-link mr-1"></i>Collega
        </button>
        <button *ngIf="references.length>0 && showDisconnect" class="btn btn-danger btn-xs" (click)="removeReference(ref);"><i class="fa fa-unlink"></i></button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <ng-container *ngFor="let t of tables_to_connected">
                <a class="dropdown-item" (click)="connectActivity(t.table)" style="cursor:pointer;">{{t.label}}</a>
            </ng-container>
        </div>
    </div>
</div>



