<div class="card mb-0">
    <!-- Barra di ricerca -->
    <div [class.section_disabled]="locked" class="card-header p-1" *ngIf="canAdd">
        <div class="row">
            <div [class.col-12]="!showQuantity" [class.col-9]="showQuantity" >
                <app-searchproduct [canExploreCatalog]="canExploreCatalog" [showPrice]="showPrice" [canNewProduct]="canNewProduct" [canEdit]="canEdit" [autoSelect]="autoSelect" [id_address]="id_address"  [showDescription]="false" [resetOnEnter]="true" [code]="" [description]="" (onEntrySelected)="add($event)"></app-searchproduct>
            </div>
            <div class="col-3" *ngIf="showQuantity">
                <div class="form-group mb-0 row">
                    <div class="d-none d-sm-block px-0 pt-2 col-4 text-right"><span>Qt</span></div>
                    <div class="col-sm-8">
                        <div class="input-group" >
                            <input type="number" min="1" step="1" class="form-control form-control-sm pr-0"  name="quantity" placeholder="..." [(ngModel)]="quantity" >
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div id="list-products" style="overflow-y: auto;">
        <!-- Lista vuota -->
        <ng-container *ngIf="list==null || list.length==0"  >
            <div class="alert alert-light m-4 text-center">
                <i class="fas fa-2x fa-qrcode"></i><br/>
                Ricerca un prodotto per iniziare oppure...
            </div>
            <div class="alert alert-light m-4 text-center" *ngIf="showOpenDocuments">
                <i class="fas fa-2x fa-list"></i><br/>
                Trova ordine cliente<br/>
                <button class="btn btn-primary btn-sm mt-2" (click)="openDocuments()">Cerca</button>
            </div>
        </ng-container>
        <!-- View 1  (deprecata) -->
        <ul class="list-group alternate" *ngIf="viewmode==1" >
            <ng-container *ngIf="list!=null && list.length>0">
                <li class="list-group-item" *ngFor="let d of list">
                    <div class="row border-bottom">
                        <div class="col-2">
                            <i class="{{d.product.icon}}"></i>
                            <h6 *ngIf="!d.editMode">{{d.quantity}} {{d.unit}}</h6>
                            <input *ngIf="d.editMode==true" class="form-control form-control-sm" type="number" min="1" step="1" name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                            

                            <span *ngIf="d.quantityPartial==0" class="badge badge-warning">aperto</span>
                            <span *ngIf="d.quantityPartial==d.quantity" class="badge badge-success">completato</span>
                            <span *ngIf="d.quantityPartial>0 && d.quantityPartial<d.quantity" class="badge badge-danger">parziale (- {{d.quantity-d.quantityPartial}} {{d.unit}})</span>
                            <span *ngIf="d.quantityPartial>0 && d.quantityPartial>d.quantity" class="badge badge-danger">parziale (+ di {{d.quantityPartial-d.quantity}} {{d.unit}})</span>
                            <span *ngIf="d.quantityPartial>0 && d.quantity ==0" class="badge badge-danger">in eccesso</span>
                            <input *ngIf="d.editMode==true" class="form-control form-control-sm" type="number" min="1" step="1" name="quantityPartial" [(ngModel)]="d.quantityPartial" />
                            

                        </div>
                        <div class="col-6" [class.col-9]="!showPrice">
                           
                            <app-productitem [sn]="d.sn" [id_document]="id_document" [showNotes]="showNotes" [product]="d.product" ></app-productitem>
                            
                            <ng-container *ngIf="showNotes && d.quantity!=d.quantityPartial">
                                <div class="badge badge-sm badge-warning" *ngIf="d.id_inventory_source>0">
                                    <i class="fas fa-exclamation"></i> Richiesto da {{d.inventoryname}} il {{d.created}}
                                </div>
                            </ng-container>

                            <small *ngIf="showNotes">{{d.note}}</small> 

                            <ng-container *ngIf="showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable">
                                <div class="badge badge-danger">Quantità non disponibili <span *ngIf="d.not_in_this_stock">in questo magazzino (max {{d.max_quantity}})<br/><button *ngIf="d.max_quantity>0" class="btn btn-xs btn-warning mt-2" (click)="resetQuantity(d)">Reimposta le quantità massime</button></span></div>
                                <div class="badge badge-info" *ngIf="d.not_in_stock">Da riordinare</div>
                                <div class="badge badge-info" *ngIf="d.in_other_stock">Disponibile in un altro magazzino</div>
                                
                            </ng-container>
                        </div>
                        <div class="col-3 text-right" *ngIf="showPrice">
                            <h6 class="text-success" *ngIf="!d.editMode">{{calculateTotalRow(d,true) | currency:"&euro; "}} <small *ngIf="d.discount1>0">(sc. {{d.discount1}}%)</small></h6>
                            <div class="row" *ngIf="d.editMode==true">
                                <div class="col-4"><label><small>Pr. i.e</small></label></div>
                                <div class="col-8">
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="checkPrice(d);calculateTotal()"/>
                                </div>
                            </div>
                            <div class="row" *ngIf="d.editMode==true">
                                
                                <div class="col-4"><label><small>Sc. %</small></label></div>
                                <div class="col-8">
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="discount1" [(ngModel)]="d.discount1" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="discount2" [(ngModel)]="d.discount2" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="discount3" [(ngModel)]="d.discount3" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    <small class="badge badge-info">sc max {{d.product.maxdiscount}} %</small>
                                </div>
                            </div>
                            <small *ngIf="!d.editMode">( IVA {{d.tax}} %)</small>
                            <div class="row" *ngIf="d.editMode==true">
                                <div class="col-4"><label><small>IVA %</small></label></div>
                                <div class="col-8"><input class="form-control form-control-sm" type="number" min="1" step="1" name="tax" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/></div>
                            </div>

                            <div class="row" *ngIf="d.editMode==true">
                                <div class="col-4"><small><label>Pr. i.i.</label></small></div>
                                <div class="col-8">
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="grossprice" value="{{calculateTotalRow(d)}}" (change)="editGrossPrice(d,$event);calculateTotal()"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-1">
                            <button type="button"  data-toggle="tooltip" (click)="deleteDetail(d)" title="Elimina" class="btn btn-danger btn-xs float-right m-1">
                                <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block"></span></button> 
                            <button *ngIf="canEdit && !d.editMode" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1" (click)="editDetail(d)">
                                <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                            <button *ngIf="canEdit && d.editMode" data-toggle="tooltip" title="Conferma" class="btn btn-success btn-xs float-right m-1" (click)="confirmEditDetail(d)">
                                <i class="fa fa-check"></i><span class="d-none d-sm-inline-block"></span></button>
                            <!--<button data-toggle="tooltip" title="Sposta" class="btn btn-secondary btn-xs float-right m-1" (click)="move(d)">
                                <i class="fas fa-arrows-alt-h"></i><span class="d-none d-sm-inline-block"></span></button>
                            -->
                            <input *ngIf="canEdit && showSelect" type="checkbox" (change)="OnSelect($event)" value="{{d.id}}">
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
        <!-- View 3 (ex view 2)-->
        <ul class="list-group alternate" *ngIf="viewmode==3" >
            <li class="list-group-item" *ngFor="let d of list;let i=index" >
                <div class="row border-bottom pb-2">
                    <div [class.section_disabled]="locked" class="col-1 px-0 bg-light text-center">
                        <h3 class="text-secondary mt-3">{{i+1}}</h3>
                        <a type="button"  data-toggle="tooltip" (click)="deleteDetail(d)" title="Elimina" class="btn btn-outline-danger btn-sm mt-3">
                            <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block"></span></a>
                    </div>
                    <div [class.col-9]="!d.editGross" [class.col-7]="d.editGross">
                        <div class="row">
                            <div [class.section_disabled]="locked"  class="col-3">
                                <small>Codice</small>
                                <input type="text" name="code" class="form-control form-control-sm" [(ngModel)]="d.code" />
                               
                            </div>
                            <div class="col-7 px-0">
                               <small>Descrizione
                                    <ng-container *ngIf="showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable">
                                        <span class="badge badge-danger">Quantità non disponibili <span *ngIf="d.not_in_this_stock">in questo magazzino (max {{d.max_quantity}})<br/>
                                            <a *ngIf="d.max_quantity>0" class="btn btn-xs btn-warning mt-2" (click)="resetQuantity(d)">Reimposta le quantità massime</a></span></span>
                                        <span class="badge badge-info" *ngIf="d.not_in_stock">Da riordinare</span>
                                        <span class="badge badge-info" *ngIf="d.in_other_stock">Disponibile in un altro magazzino</span>
                                    </ng-container>
                                </small>
                                <small>
                                    <div class="input-group input-group-sm">
                                        <input [class.section_disabled]="locked" [readonly]="descriptionReadonly" type="text" class="form-control form-control-sm"  name="description" [(ngModel)]="d.description">
                                        
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <i *ngIf="!d.collapse" (click)="d.collapse=true" data-toggle="tooltip" title="Espandi note" class="fa fa-arrow-down"></i>
                                                <i *ngIf="d.collapse" (click)="d.collapse=false" data-toggle="tooltip" title="Riduci note"  class="fa fa-arrow-up"></i>
                                            </div>
                                       
                                            <a *ngIf="d.product" class="btn btn-default" [disabled]="!d.product.id" 
                                                data-toggle="tooltip" title="Dettaglio prodotto" (click)="openProductDetail(d.product)">
                                                <i class="fa fa-pencil-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <textarea [class.section_disabled]="locked" class="form-control form-control-sm" name="note" [(ngModel)]="d.note" *ngIf="d.collapse"></textarea>
                                </small>
                                <div class="text-center">
                                    <div *ngIf="d.product && d.product.stock" (click)="openStock(d.product)" [class.badge-danger]="d.product.stock<0" [class.badge-success]="d.product.stock>0" class="badge">attualmente in magazzino <strong>{{d.product.stock}} {{d.product.unit}}</strong>&nbsp;<i class="fa fa-boxes"></i></div>
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-2">
                                <small>SN</small>
                                <input type="text" class="form-control form-control-sm" name="sn" [(ngModel)]="d.sn" />
                            </div>
                        </div>
                        <div [class.section_disabled]="locked"  class="row">
                            <div class="col-3">
                                <small>Qt <span *ngIf="d.editQuantityPartial">parziali</span></small>
                                <div class="input-group input-group-sm">
                                    <input *ngIf="!d.editQuantityPartial" type="number" class="form-control form-control-sm" min="1" step="1" name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                                    <input *ngIf="d.editQuantityPartial" class="form-control form-control-sm" type="number" min="1" step="1" name="quantityPartial" [(ngModel)]="d.quantityPartial" />
                                    <div class="input-group-append" (click)="d.editQuantityPartial=!d.editQuantityPartial"  style="cursor:pointer;">
                                        <span class="input-group-text" [class.bg-info]="d.editQuantityPartial">
                                            <i   class="fa fa-sync" ></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 pl-0">
                                <small>UM</small>
                                <input  type="text" class="form-control form-control-sm"  name="unit" [(ngModel)]="d.unit"/>
                            </div>
                            <div class="col-3 pl-0">
                                <small>Pr. Un. (€/um)</small>
                                <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="checkPrice(d);calculateTotal()"/>
                            </div>
                            <div class="col-2 pl-0">
                                <small>Sc. (%)</small>
                                <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="discount1" [(ngModel)]="d.discount1" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="discount2" [(ngModel)]="d.discount2" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="discount3" [(ngModel)]="d.discount3" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                
                                <small><small *ngIf="d.product" class="badge badge-info">sc max {{d.product.maxdiscount}} %</small></small>
                            </div>
                            <div class="col-2 pl-0">
                                <small>IVA (%)</small>
                                <input  type="number" class="form-control form-control-sm" min="1" step="1" name="tax" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                            </div>
                        </div>
                    </div>
                    <div [class.col-2]="!d.editGross" [class.col-4]="d.editGross" class="px-0 text-right bg-light">
                            <div [class.section_disabled]="locked">
                                <small>Totale</small>
                                <h6 *ngIf="!d.editGross" (click)="d.editGross=true" class="text-success mb-0" data-toggle="tooltip" title="Modifica"  style="cursor:pointer;">{{calculateTotalRow(d,true)  | currency:"&euro; "}}</h6>
                                <div class="input-group input-group-sm" *ngIf="d.editGross">
                                    <input type="number" class="form-control form-control-sm mb-0" min="0" step="0.01" name="total_gross" value="{{calculateTotalRow(d,true)}}" (change)="calculateNetDetail($event,d)" />
                               
                                    <div class="input-group-append">
                                        <div (click)="d.editGross=false" class="input-group-text bg-success" style="cursor:pointer;"><i class="fa fa-check"></i></div>
                                    </div>
                                </div>
                                <p data-toggle="tooltip" title="IVA esclusa">{{calculateTotalRow(d)  | currency:"&euro; "}}</p>
                                
                                <span *ngIf="d.quantityPartial==0" class="badge badge-warning">aperto</span>
                                <span *ngIf="d.quantityPartial==d.quantity" class="badge badge-success">completato</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial<d.quantity" class="badge badge-danger">parziale (- {{d.quantity-d.quantityPartial}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial>d.quantity" class="badge badge-danger">parziale (+ di {{d.quantityPartial-d.quantity}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantity ==0" class="badge badge-danger">in eccesso</span>
                            </div>
                            <ng-container *ngIf="d.id_inventory_detail_unload>0">
                                <span class="badge badge-warning" (click)="openInventoryDetail(d.id_inventory_detail_unload)" style="cursor:pointer;"><i class="fas fa-boxes mr-2"></i>Movimentato scarico</span>
                            </ng-container>

                            <ng-container *ngIf="d.id_inventory_detail_load>0">
                                <span class="badge badge-warning" (click)="openInventoryDetail(d.id_inventory_detail_load)" style="cursor:pointer;"><i class="fas fa-boxes mr-2"></i>Movimentato carico</span>
                            </ng-container>
                    </div>
                    <div class="col-12 px-0" *ngIf="d.id_product==0">
                        <div class="alert alert-warning">
                            <i class="fa fa-exclamation mr-2"></i>Articolo non presente nel catalogo
                            <a class="btn btn-primary btn-xs float-right" (click)="newProduct(d)"><i class="far fa-plus-square mr-2"></i>Crea articolo</a>
                            <a class="btn btn-info btn-xs ml-2 float-right" (click)="assignProduct(d)"><i class="fas fa-link mr-2"></i>Associa articolo del catalogo</a>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <!-- View 2 (corrente)-->
        <ul class="list-group alternate text-sm" *ngIf="viewmode==2" >
            <li class="list-group-item p-0" *ngFor="let d of list;let i=index" >
                
                <div class="pb-1" style="border-bottom: 1px solid rgba(0,0,0,0.2)" [class.alert-info]="d.edit_row" [class.alert-warning]="!d.edit_row && d.id_product==0" [class.alert-danger]="!d.edit_row && showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable">
                    <!-- Alert articolo non presente -->
                    <div class="col-12 pt-1 pl-3" *ngIf="d.id_product==0">
                        <i class="fa fa-exclamation-triangle mr-2"></i>Articolo non presente nel catalogo!
                        <a class="btn btn-success btn-xs ml-1" (click)="newProduct(d)"><i class="fas fa-plus mr-2"></i>Crea articolo</a>
                        <a class="btn btn-primary btn-xs ml-1" (click)="assignProduct(d)"><i class="fas fa-link mr-2"></i>Associa ad articolo in catalogo</a>
                    </div>
                    <!-- Alert quantità non disponibili -->
                    <div class="col-12 pt-1 pl-3" *ngIf="showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable">
                        <i class="fa fa-exclamation-triangle mr-2"></i>Qt non disponibili
                        <span *ngIf="d.not_in_this_stock"> in questo magazzino (max {{d.max_quantity}})
                            <a *ngIf="d.max_quantity>0" class="btn btn-xs btn-primary ml-1" (click)="resetQuantity(d)"><i class="fab fa-creative-commons-zero mr-2"></i>Reimposta le quantità massime</a>
                        </span>
                        <span class="badge badge-secondary" *ngIf="d.not_in_stock">Da riordinare</span>
                        <span class="badge badge-secondary" *ngIf="d.in_other_stock">Disponibile in un altro magazzino</span>
                    </div>
                    <!-- View ridotta -->
                    <div class="col-12" *ngIf="!d.edit_row">
                        <div class="row pt-1">
                            <div class="col-1 p-1">
                                <b class="text-primary mt-2 d-none d-md-inline">{{i+1}}</b>
                                <a class="btn btn-secondary btn-xs m-1" type="button" (click)="d.edit_row=true" data-toggle="tooltip" title="Espandi" ><i class="fas fa-expand-alt"></i></a>
                            </div>
                            <div class="col-3 px-1">
                                <small>
                                    <span class="text-primary d-none d-md-inline">{{d.code}}</span>
                                    <span>&ensp;</span>
                                    <span *ngIf="d.id_inventory_detail_unload>0" class="badge badge-warning text-danger ml-1" data-toggle="tooltip" title="Clicca per dettaglio magazzino" (click)="openInventoryDetail(d.id_inventory_detail_unload)"
                                         style="cursor:pointer;"><i class="fas fa-boxes mr-1"></i>Scaricato
                                    </span>
                                    <span *ngIf="d.id_inventory_detail_load>0" class="badge badge-warning  text-success ml-1" data-toggle="tooltip" title="Clicca per dettaglio magazzino" (click)="openInventoryDetail(d.id_inventory_detail_load)"
                                        style="cursor:pointer;"><i class="fas fa-boxes mr-1"></i>Caricato
                                   </span>
                                    </small><br>
                                <div class="pt-1 w-100 text-truncate" >
                                    <i *ngIf="d.product && d.product.id" class="fas fa-pencil-alt mr-1" (click)="openProductDetail(d.product)" style="cursor:pointer;" data-toggle="tooltip" title="Clicca per dettaglio prodotto"></i>
                                    {{d.description}}&nbsp;<span class="badge badge-info" *ngIf="d.sn">{{d.sn}}</span><small class="px-1" style="cursor:pointer" data-toggle="tooltip" title="{{d.note}}" *ngIf="d.note">*</small>
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-1 pl-0 pr-1">
                                <small><span *ngIf="!d.unit">Qt</span>{{d.unit}}</small>
                                <input type="number" class="form-control form-control-sm font-weight-bold" min="1" [class.alert-danger]="d.quantity<1"  data-toggle="tooltip" title="Le quantità devono essere sempre maggiori di 0"
                                    style="height:1.5rem;" name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                            </div>
                            <div [class.section_disabled]="locked" class="col-1 pl-0 pr-1">
                                <small>€/<span *ngIf="!d.unit">UM</span>{{d.unit}}</small>
                                <!-- <div class="pt-1 w-100 text-truncate" style="text-overflow:clip;">{{d.netprice}}</div> -->
                                <input class="form-control form-control-sm font-weight-bold" min="1" style="height:1.5rem;" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="checkPrice(d);calculateTotal()"/>
                            </div>
                            <div class="col-2 px-0 text-center">
                                <small>Sconti (%)</small>
                                <div class="pt-1 w-100 text-truncate" style="text-overflow:clip;">{{d.discount1}} + {{d.discount2}} + {{d.discount3}}</div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-1 px-1 text-center">
                                <small>IVA</small>
                                <select class="form-control form-control-sm py-0"style="font-size:85%;height:1.5rem;"  name="tax" [(ngModel)]="d.taxItem" (ngModelChange)="setTax(d,$event);calculateTotal()">
                                    <option *ngFor="let t of taxes" [ngValue]="t">{{t.tax}}% - {{t.label}}</option>
                                </select>
                                <!-- <div class="pt-1 w-100 text-truncate" data-toggle="tooltip" title="{{calculateTotalRow(d,true)-calculateTotalRow(d)  | currency:'&euro; '}}" style="text-overflow:clip;">{{d.tax}}</div>  -->
                            </div>
                            <div [class.section_disabled]="locked" class="col-2 px-1">
                                <small><b class="d-none d-md-inline">TOTALE</b></small>
                                    <ng-container *ngIf="d.product.netcost>0">
                                        <span class="float-right" data-toggle="tooltip" title="Margine di profitto">
                                            <span *ngIf="((calculateTotalRow(d)/d.quantity)-d.product.netcost)/d.product.netcost*100>0" class="badge badge-success ">
                                                +{{((calculateTotalRow(d)/d.quantity)-d.product.netcost)/d.product.netcost*100 | number: '1.2-2'}}%
                                            </span>
                                            <span *ngIf="((calculateTotalRow(d)/d.quantity)-d.product.netcost)/d.product.netcost*100<=0" class="badge badge-danger">
                                                {{((calculateTotalRow(d)/d.quantity)-d.product.netcost)/d.product.netcost*100 | number: '1.2-2'}}%
                                            </span>
                                        </span>
                                    </ng-container>
                                
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend" *ngIf="d.editGross">
                                        <div (click)="d.editGross=false" class="input-group-text bg-success" style="cursor:pointer;"><i class="fa fa-check"></i></div>
                                    </div>
                                    <input *ngIf="!d.editGross" class="form-control form-control-sm font-weight-bold text-right" data-toggle="tooltip" title="{{calculateTotalRow(d)  | currency:'&euro; '}} (IVA esclusa)" (click)="d.editGross=true" style="height:1.5rem;" value="{{calculateTotalRow(d,true) | currency:'&euro; '}}" />
                                    <input *ngIf="d.editGross" class="form-control form-control-sm font-weight-bold text-right" data-toggle="tooltip" title="{{calculateTotalRow(d) | currency:'&euro; '}} (IVA esclusa)" min="0"  style="height:1.5rem;" name="total_gross" value="{{calculateTotalRow(d,true)}}" (change)="calculateNetDetail($event,d)" />
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-1 p-1 text-right">
                                 <div class="float-right text-gray">
                                    <i *ngIf="i>0" data-toggle="tooltip" title="Sposta sopra" style="cursor:pointer;" class="fas fa-chevron-up" (click)="array_move(d['ordering'],d['ordering']-1);"></i><br>
                                    <i *ngIf="i<(list.length-1)" data-toggle="tooltip" title="Sposta sotto" style="cursor:pointer;" class="fas fa-chevron-down" (click)="array_move(d['ordering'],d['ordering']+1);"></i>
                                </div> 
                                <a class="btn btn-danger btn-xs float-right m-1" type="button" data-toggle="tooltip" (click)="deleteDetail(d)" title="Elimina" ><i class="fas fa-trash"></i></a>
                               
                            </div>
                        </div>
                    </div>
                    <!-- View completa per modifica -->
                    <div class="col-12" *ngIf="d.edit_row">
                        <div class="row">
                            <div class="col-1 p-1">
                                <!-- <small class="text-primary">N. {{i+1}}</small><br> -->
                                <b class="text-primary mt-2 d-none d-md-inline">{{i+1}}</b>
                                <a type="button" (click)="d.edit_row=false" data-toggle="tooltip" title="Comprimi" class="btn btn-success btn-xs m-1"><i class="fas fa-compress-alt"></i></a>
                            </div>
                            <div [class.section_disabled]="locked" class="col-2 px-1">
                                <small>Codice</small>
                                <input type="text" name="code" class="form-control form-control-sm py-1" [(ngModel)]="d.code" />
                            </div>
                            <div class="col-4 px-0">
                               <small data-toggle="tooltip" title="{{d.id_product}}">Descrizione<small class="px-1" *ngIf="d.note">*</small></small>
                                <div class="input-group input-group-sm" >
                                    <div class="input-group-prepend" *ngIf="d.id_product!=0" [class.section_disabled]="locked">
                                        <div class="input-group-text input-group-text-sm bg-primary">
                                            <a data-toggle="tooltip" title="Disasssocia da articolo in catalogo" (click)="d.product='';d.id_product=0"  style="cursor:pointer;">
                                                <i class="fa fa-unlink"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="input-group-prepend" *ngIf="d.product.id" >
                                        <div class="input-group-text input-group-text-sm bg-primary">
                                            <a data-toggle="tooltip" title="Dettaglio prodotto" (click)="openProductDetail(d.product)" style="cursor:pointer;">
                                                <i class="fa fa-pencil-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <input [class.section_disabled]="locked" [readonly]="descriptionReadonly" type="text" class="form-control form-control-sm"  name="description" [(ngModel)]="d.description">
                                    <div class="input-group-append">
                                        <div class="input-group-text input-group-text-sm bg-primary">
                                            <i *ngIf="!d.collapse" (click)="d.collapse=true" data-toggle="tooltip" title="Espandi note" class="fa fa-chevron-down" style="cursor:pointer;"></i>
                                            <i *ngIf="d.collapse" (click)="d.collapse=false" data-toggle="tooltip" title="Riduci note"  class="fa fa-chevron-up" style="cursor:pointer;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-2 px-1">
                                <small>SN</small>
                                <input type="text" class="form-control form-control-sm" name="sn" [(ngModel)]="d.sn" />
                            </div>
                            <div class="col-3 px-0">
                                <small>Stato</small><br>
                                <span *ngIf="d.product && d.product.stock" (click)="openStock(d.product)" [class.badge-danger]="d.product.stock<0" [class.badge-success]="d.product.stock>0"  style="cursor:pointer;"
                                    class="badge mr-1"><i class="fa fa-boxes mr-1"></i>{{d.product.stock}} {{d.product.unit}}</span>
                                <span *ngIf="d.quantityPartial==0" class="badge badge-warning">aperto</span>
                                <span *ngIf="d.quantityPartial==d.quantity" class="badge badge-success">completato</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial<d.quantity" class="badge badge-danger">parziale (- {{d.quantity-d.quantityPartial}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial>d.quantity" class="badge badge-danger">parziale (+ di {{d.quantityPartial-d.quantity}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantity ==0" class="badge badge-danger">in eccesso</span>
                                <span *ngIf="d.id_inventory_detail_unload>0" class="badge badge-warning text-danger ml-1" (click)="openInventoryDetail(d.id_inventory_detail_unload)" style="cursor:pointer;"><i class="fas fa-boxes mr-1"></i>Scaricato</span>
                                <span *ngIf="d.id_inventory_detail_load>0" class="badge badge-warning text-success ml-1" (click)="openInventoryDetail(d.id_inventory_detail_load)" style="cursor:pointer;"><i class="fas fa-boxes mr-1"></i>Caricato</span>
                                
                            </div>
                        </div>
                        <div class="row" *ngIf="d.collapse">
                            <div class="col-1 text-right pr-0"><small>Note</small></div>
                            <div [class.section_disabled]="locked" class="col-8 px-1">
                                <textarea class="form-control form-control-sm" name="note" [(ngModel)]="d.note" ></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div [class.section_disabled]="locked" class="col-1 pl-1 pr-0">
                                <div class="pl-1">
                                    <small>Qt <span class="d-none d-md-inline" *ngIf="d.editQuantityPartial">parziali</span></small>
                                    <input *ngIf="!d.editQuantityPartial" type="number" class="form-control form-control-sm" [class.alert-danger]="d.quantity<1" min="1" data-toggle="tooltip" title="Le quantità devono essere sempre maggiori di 0"
                                        name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                                    <input *ngIf="d.editQuantityPartial" type="number" class="form-control form-control-sm alert-warning" min="1"  name="quantityPartial"  data-toggle="tooltip" title="Indicare le quantità presenti (parziali) rispetto a quelle dichiarate"
                                    [(ngModel)]="d.quantityPartial" />
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-2 pl-0 pr-1">
                                <small>UM</small>
                                <div class="input-group input-group-sm">
                                    <input type="text" class="form-control form-control-sm"name="unit" [(ngModel)]="d.unit"/>
                                    <div class="input-group-append" (click)="d.editQuantityPartial=!d.editQuantityPartial" data-toggle="tooltip" title="Quantità Totali/Parziali" style="cursor:pointer;">
                                        <span class="input-group-text bg-primary" *ngIf="!d.editQuantityPartial">T</span>
                                        <span class="input-group-text bg-warning" *ngIf="d.editQuantityPartial">P</span>
                                    </div>
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-2 pl-0 pr-1">
                                <small><span class="d-none d-md-inline">Prezzo Unitario </span>(€/um)</small>
                                <input class="form-control form-control-sm" min="1" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="checkPrice(d);calculateTotal()"/>
                            </div>
                            
                            <div [class.section_disabled]="locked" class="col-2 px-0">
                                <small>Sconti (%)</small><small data-toggle="tooltip" title="max {{d.product.maxdiscount}}%" *ngIf="d.product" class="text-info ml-2"><i class="fa fa-exclamation-circle mr-1"></i><span class="d-none d-md-inline">max {{d.product.maxdiscount}}%</span></small>
                                <div class="input-group input-group-sm">
                                    <input class="form-control form-control-sm" min="1"  name="discount1" [(ngModel)]="d.discount1" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    <input class="form-control form-control-sm" min="1"  name="discount2" [(ngModel)]="d.discount2" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    <input class="form-control form-control-sm" min="1"  name="discount3" [(ngModel)]="d.discount3" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-1 pl-1 pr-0">
                                <small>IVA</small>
                                <select class="form-control form-control-sm"  name="tax" [(ngModel)]="d.taxItem" (ngModelChange)="setTax(d,$event);calculateTotal()">
                                    <option *ngFor="let t of taxes" [ngValue]="t">{{t.tax}}% - {{t.label}}</option>
                                </select>
                                <!--
                                <input class="form-control form-control-sm" min="1" step="1" name="tax2" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                                -->
                            </div>
                            <div class="col-2 px-1 section_disabled">
                                <small><span class="d-none d-md-inline">TOTALE </span>IVA esclusa</small>
                                <div class="input-group input-group-sm">
                                    <input class="form-control form-control-sm" min="0" name="total_gross" value="{{calculateTotalRow(d)  | currency:'&euro; '}}" (change)="calculateNetDetail($event,d)" />
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-2 pl-0 pr-1 text-right">
                                <small><b class="d-none d-md-inline">TOTALE </b>IVA inclusa</small>
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend" *ngIf="d.editGross">
                                        <div (click)="d.editGross=false" class="input-group-text bg-success" style="cursor:pointer;"><i class="fa fa-check"></i></div>
                                    </div>
                                    <input *ngIf="!d.editGross" class="form-control form-control-sm font-weight-bold text-right" data-toggle="tooltip" title="{{calculateTotalRow(d)  | currency:'&euro; '}} (IVA esclusa)" (click)="d.editGross=true" value="{{calculateTotalRow(d,true) | currency:'&euro; '}}" />
                                    <input *ngIf="d.editGross" class="form-control form-control-sm font-weight-bold text-right" data-toggle="tooltip" title="{{calculateTotalRow(d) | currency:'&euro; '}} (IVA esclusa)" min="0" name="total_gross" value="{{calculateTotalRow(d,true)}}" (change)="calculateNetDetail($event,d)" />
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
            </li>
        </ul>
    </div>
</div>



<!--
<div class="row">
    <div class="col-12">
        <table class="table">
            <thead>
                <th>ID</th>
                <th>Codice</th>
                <th>Descrizione</th>
                <th>SN</th>
                <th>Qt</th>
                <th>Um</th>
                <th>Pr. Unitario</th>
                <th>Sc. (%)</th>
                <th>IVA (%)</th>
                <th>Totale</th>
            </thead>
            <tbody *ngFor="let d of list;let i=index">
                <tr>
                    <td>{{i}}</td>
                    <td><input type="text" name="code" class="form-control form-control-sm" [(ngModel)]="d.code" /></td>
                    <td> 
                        <input [readonly]="descriptionReadonly" type="text" class="form-control form-control-sm"  name="description" [(ngModel)]="d.description">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <i *ngIf="!d.collapse" (click)="d.collapse=true" class="fa fa-arrow-down"></i>
                                <i *ngIf="d.collapse" (click)="d.collapse=false" class="fa fa-arrow-up"></i>
                            </div>
                        </div>
                        <div class="input-group-append">
                            <button *ngIf="d.product" class="btn btn-default" [disabled]="!d.product.id" (click)="openProductDetail(d.product)"><i class="fa fa-pencil-alt"></i></button>
                           
                        </div>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="sn" [(ngModel)]="d.sn" />
                    </td>
                    <td>
                        <div class="input-group input-group-sm">
                            <input *ngIf="!d.editQuantityPartial" type="number" class="form-control form-control-sm" min="1" step="1" name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                            <input *ngIf="d.editQuantityPartial" class="form-control form-control-sm" type="number" min="1" step="1" name="quantityPartial" [(ngModel)]="d.quantityPartial" />
                            <div class="input-group-append" (click)="d.editQuantityPartial=!d.editQuantityPartial" >
                                <span class="input-group-text" [class.bg-info]="d.editQuantityPartial">
                                    <i   class="fa fa-sync" ></i>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <input  type="text" class="form-control form-control-sm"  name="unit" [(ngModel)]="d.unit"/>
                    </td>
                    <td >
                        <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="discount" [(ngModel)]="d.discount" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                        <small *ngIf="d.product" class="badge badge-info">sc max {{d.product.maxdiscount}} %</small>
                    </td>
                    <td>
                        <input  type="number" class="form-control form-control-sm" min="1" step="1" name="tax" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                    </td>
                    <td>
                        
                                <h5 *ngIf="!d.editGross" (click)="d.editGross=true" class="text-success" data-toggle="tooltip" title="">{{d.netprice*d.quantity*(1-d.discount/100)*(1+d.tax/100)  | currency:"&euro; "}}</h5>
                                <div class="input-group input-group-sm" *ngIf="d.editGross">
                                    <input type="number" class="form-control form-control-sm" min="0" step="0.01" name="total_gross" value="{{d.netprice*d.quantity*(1-d.discount/100)*(1+d.tax/100)}}" (change)="calculateNetDetail($event,d)" />
                               
                                    <div class="input-group-append">
                                        <div (click)="d.editGross=false" class="input-group-text bg-success"  style="cursor:pointer;"><i class="fa fa-check"></i></div>
                                    </div>
                                </div>
                                <p data-toggle="tooltip" title="IVA esclusa">{{d.netprice*d.quantity*(1-d.discount/100)  | currency:"&euro; "}}</p>
                                
                                <span *ngIf="d.quantityPartial==0" class="badge badge-warning">aperto</span>
                                <span *ngIf="d.quantityPartial==d.quantity" class="badge badge-success">completato</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial<d.quantity" class="badge badge-danger">parziale (- {{d.quantity-d.quantityPartial}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial>d.quantity" class="badge badge-danger">parziale (+ di {{d.quantityPartial-d.quantity}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantity ==0" class="badge badge-danger">in eccesso</span>
                                
                                <ng-container *ngIf="d.id_inventory_detail>0">
                                    <span class="badge badge-warning" (click)="openInventoryDetail(d.id_inventory_detail)"  style="cursor:pointer;"><i class="fas fa-boxes ml-2"></i>Movimentato</span>
                                </ng-container>
                    </td>
                </tr>
            </tbody>
            
        
        </table>

    </div>
</div>
-->