<div class="row my-1">

  <div class="col-lg-2 pr-0" *ngIf="filterbox">
    <div class="row">
        <div class="col-4 col-lg-12">
           
                <small>
                    <app-treecategories [class]="'card p-1'" (id_selected)="filter_id_category.value=$event;getItems()" [showAll]="true" [id]="filter_id_category.value" [showCountProduct]="false" [editButton]="false" #tree [list]="categories"></app-treecategories>
               
                
                    <ul class="card small-box list-group treeviewlist p-1 mt-2">
                        <li class="list-group-item" [class.active]="filter_id_inventory.value==''" (click)="filter_id_inventory.value='';getItems()">Tutti i magazzini<button class="btn btn-xs btn-default float-right" (click)="openInventoryManager()"><i class="fa fa-cog"></i></button></li>
                        <li class="list-group-item" [class.active]="filter_id_inventory.value==i.id" *ngFor="let i of inventories" (click)="filter_id_inventory.value=i.id;getItems()">{{i.name}}</li>
                        <div class="icon"><i class="fas fa-boxes"></i></div>
                    </ul>

                </small>
            
        </div>
        <div class="col-4 col-lg-12">
           
                <small>

                    <app-filterdate [date_type]="100" [class]="'card treeviewlist p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>

                </small>
           
        </div>
        <div class="col-4 col-lg-12">
            
                <small>
                    
                    <ul class="card small-box list-group treeviewlist p-1">
                        <li class="list-group-item" [class.active]="filter_type.value==''" (click)="filter_type.value='';getItems()" style="cursor:pointer;">Tutte le tipologie</li>
                        <li class="list-group-item" [class.active]="filter_type.value=='1'" (click)="filter_type.value='1';getItems()" style="cursor:pointer;">Carico <button class="btn btn-xs btn-default float-right" (click)="openInventoryManager()"><i class="fa fa-cog"></i></button></li>
                        <li class="list-group-item" [class.active]="filter_type.value=='2'" (click)="filter_type.value='2';getItems()" style="cursor:pointer;">Scarico <button class="btn btn-xs btn-default float-right" (click)="openInventoryManager()"><i class="fa fa-cog"></i></button></li>
                        <div class="icon"><i class="fas fa-exchange-alt"></i></div>
                    </ul>

                </small>
          
        </div>
        <div class="col-4 col-lg-12">
           
                <small>
                    <ul class="card small-box list-group treeviewlist p-1">
                        <li class="list-group-item" [class.active]="filter_table.value==' '" (click)="filter_table.value=' ';getItems()" style="cursor:pointer;">Tutte i riferimenti</li>
                        <li class="list-group-item" *ngIf="isModuleEnabled('documents')" [class.active]="filter_table.value=='documents'" (click)="filter_table.value='documents';getItems()" style="cursor:pointer;">Documenti</li>
                        <li class="list-group-item" *ngIf="isModuleEnabled('booking')" [class.active]="filter_table.value=='booking'" (click)="filter_table.value='booking';getItems()" style="cursor:pointer;">Prenotazioni</li>
                        <li class="list-group-item" *ngIf="isModuleEnabled('address')" [class.active]="filter_table.value=='addresses'" (click)="filter_table.value='addresses';getItems()" style="cursor:pointer;">Anagrafica</li>
                        <li class="list-group-item" *ngIf="isModuleEnabled('installations')" [class.active]="filter_table.value=='installations'" (click)="filter_table.value='installations';getItems()" style="cursor:pointer;">Installazioni</li>
                        <li class="list-group-item" [class.active]="filter_table.value==''" (click)="filter_table.value='';getItems()" style="cursor:pointer;">Nessun riferimento</li>

                        <div class="icon"><i class="far fa-file"></i></div>
                    </ul>
                </small>
            
        </div>


    </div>
  </div>
  <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
    <div class="card card-outline">
        <div class="p-0">
            <div class="row" >
                <div class="col-4" >
                    <div *ngIf="filterbox" class="input-group input-group-info input-group-sm p-1">
                        <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome prodotto o seriale" [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                        <div class="input-group-append ">
                            <button type="submit" class="btn btn-default" data-toggle="tooltip" title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                            <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''" data-toggle="tooltip" title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                        </div>
                    </div>
                </div>
                <div class="col-2 pl-0">
                    <div class="input-group input-group-sm m-1" >
                        
                        <select class="form-control form-control-sm" [(ngModel)]="filter_product_id_type.value" (ngModelChange)="getItems()">
                            <option value="">Tutte le tipologie</option>
                            <option *ngFor=" let r of type_products" [ngValue]="r.id">{{r.name}}</option>
                        </select>
                        
                    </div>
                </div>

                <div class="col-3">
                    <select class="form-control form-control-sm m-1" [(ngModel)]="filter_id_user.value" (ngModelChange)="getItems()">
                        <option value="">Tutti gli operatori</option>
                        <ng-container *ngFor="let u of users">
                            <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-3" >
                    <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                    <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block"></span></button>
                    <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block"></span></button>
                    <button  (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block"></span></button>
                    <button (click)="openDetail(null,[{'name':'id_product','value':id_product},{'name':'sn','value':sn},{'name':'id_table','value':id_table},{'name':'table','value':table}])"  class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
        
                    <!-- <button *ngIf="id_product==0" (click)="load()" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-upload"></i><span class="d-none d-sm-inline-block"></span> Carica</button> -->
                    <!-- <button *ngIf="id_product==0" (click)="unload()" class="btn btn-danger btn-xs float-right m-1"><i class="fas fa-download"></i><span class="d-none d-sm-inline-block"></span> Scarica</button> -->
                    
                    <!--<button class="btn btn-success btn-xs float-right m-1" (click)="fastout()"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block"></span></button>-->

                </div>
            </div>

            <div *ngIf="list==null" class="m-4">
                <i class="fa-2x fas fa-info text-info mr-3"></i>
                <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
            </div>

            <form [formGroup]="form" *ngIf="list!=null">
                <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                    <thead class="bg-light">
                        <th width="20px">
                            <input #checkboxselectall  type="checkbox"
                            (change)="onChangeAll($event.target.checked)" />
                        </th>
                        <th width="120px">
                            Data
                            <button class="btn btn-tool" (click)="switchOrdering('i.date')">
                                <i class="fas fa-sort {{classOrdering('i.date')}}"  ></i>
                            </button>
                        </th>
                        <th >
                            Prodotto
                            <button class="btn btn-tool" (click)="switchOrdering('i.reference')">
                                <i class="fas fa-sort {{classOrdering('i.reference')}}"  ></i>
                            </button>
                        </th>
                       
                        <th class="d-none d-sm-table-cell" width="70px">
                            QT
                            <button class="btn btn-tool" (click)="switchOrdering('i.typedocument')">
                                <i class="fas fa-sort {{classOrdering('i.typedocument')}}"  ></i>
                            </button>
                        </th >
                        <th class="d-none d-sm-table-cell">
                            Prezzo
                        </th>
                        <th width="120px">
                            Magazzino
                            <button class="btn btn-tool" (click)="switchOrdering('i.inventoryname')">
                                <i class="fas fa-sort {{classOrdering('i.amount')}}"  ></i>
                            </button>
                        </th>
                        <th *ngIf="id_product>0">Giacenza</th>
                        <th>Documento</th>
                        <th width="80px"></th>
                    </thead>
                    <thead>
                        <tr class="bg-white" *ngIf="total!=null">
                            <td></td>
                            <td class="pl-4"><b>TOTALE</b></td>
                            <td></td>
                            <td><span ><b>{{total.quantity}}</b></span></td>
                            <td colspan="4"><span ><b>{{total.amount | currency:"&euro; "}}</b></span></td>
                        </tr>
                    </thead>
                    <tbody>
                    
                        <!-- elemento ripetuto -->
                        <tr *ngFor="let inventory of list">
                            <td>
                                <input type="checkbox" #checkrecord id_record="{{inventory.id}}"
                                (change)="onChange(inventory.id, $event.target.checked)" [checked]="checkedAll"
                                />
                            </td>
                            <td>
                                <span *ngIf="inventory.type==1" class="badge badge-success"><i class="fa fa-upload"></i></span>
                                <span *ngIf="inventory.type==2" class="badge badge-danger"><i class="fa fa-download"></i></span>
                        
                                <a [class.trashed]="inventory.status == 2" (click)="openDetail(inventory)"  data-toggle="tooltip" title="{{inventory.date | date:'EEEE'}}">
                                    {{inventory.date | date:'dd/M/yyyy'}}
                                </a>
                            </td>
                            <td >
                                {{ inventory.description }}<span *ngIf="inventory.sn">&nbsp;<div class="badge badge-info">{{ inventory.sn }}</div></span>
                                &nbsp;<i class="fa fa-pencil-alt" (click)="openProduct(inventory.id_product)"></i>
                                <ng-container *ngIf="id_product==0">
                                    &nbsp;<i class="fa fa-boxes" (click)="openHistory(inventory.id_product)"></i>
                                </ng-container>
                                <br/><small>{{inventory.note}}</small>
                            
                            </td>
                            <td class="d-none d-sm-table-cell">
                                <span *ngIf="inventory.type==1">+</span>
                                <span *ngIf="inventory.type==2">-</span>
                                {{inventory.quantity}}
                            </td>
                            <td class="d-none d-sm-table-cell">
                                <span *ngIf="inventory.type==1">-</span>
                                <span *ngIf="inventory.type==2">+</span>
                                {{calculateTotalRow(inventory) | currency:"&euro; "}}
                            </td>
                            <td  >
                                {{ inventory.inventoryname}}
                                <br/>
                                <small>
                                    {{inventory.username}}
                                </small>

                            </td>
                            <td *ngIf="id_product>0" class="text-center">
                                <h5 [class.text-success]="inventory.stock>-1" [class.text-danger]="inventory.stock < 0">  {{inventory.stock}}</h5></td>
                            <td>
                                <app-reference [reference]="inventory.reference" [table]="inventory.table"></app-reference>
                            </td>
                           
                            <td>
                                <button type="button" (click)="openDetail(inventory)"  data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs m-1 float-right">
                                    <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                                
                                <button type="button" (click)="move(inventory)" data-toggle="tooltip" title="Sposta gli elementi selezionati in un altro magazzino"  class="btn btn-secondary btn-xs float-right m-1"><i class="fas fa-arrows-alt-h"></i><span class="d-none d-sm-inline-block"></span></button>

                            </td>
                            
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="bg-white" *ngIf="total!=null">
                            <td></td>
                            <td class="pl-4"><b>TOTALE</b></td>
                            <td></td>
                            <td><span ><b>{{total.quantity}}</b></span></td>
                            <td colspan="4"><span ><b>{{total.amount | currency:"&euro; "}}</b></span></td>
                        </tr>
                    </tfoot>
                </table>
            </form>

            <div class="card-footer clearfix py-1">
                <app-pagination [model]="this" ></app-pagination>
            </div>

        </div>
    </div>
  </div>
</div>

<div class="modal-footer" *ngIf="modalWindow">
    <button class="btn btn-secondary" (click)="modalWindow.close()">Chiudi</button>
</div>