import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { Type } from '../db/type';
import {WSService} from 'projects/core/src/include/service'

@Injectable({
  providedIn: 'root'
})

export class TypesService extends WSService<Type> {

  constructor() { super("types")}


  
  getTypes(table:string):Observable<Type[]>{
    return  Globals.ws.requestUrl<Type[]>("types","getTypes",[table]);
    
  }


}
