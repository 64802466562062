<app-window [title]="title">
    <form role="form" id="detailForm">
      
        
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>Data</label>
                            <div class="input-group" >
                                <input class="form-control form-control-sm" type="date"  name="date"  [(ngModel)]="record.date"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="form-group">
                            <label>Descrizione</label>
                            <input type="text" class="form-control form-control-sm" placeholder="..." name="description" [(ngModel)]="record.description">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>Calcolato</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <!-- <input type="number" class="form-control form-control-sm"  min="0" step="0.01" name="amount" placeholder="..." [(ngModel)]="record.amount" > -->
                                <span class="form-control form-control-sm bg-light" >{{record.amount}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group ">
                            <label>Reale</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <input type="number" class="form-control form-control-sm" min="0" step="0.01" name="paid" placeholder="..." [(ngModel)]="record.paid " >
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group ">
                            <label>Operatore</label>
                            <div class="input-group input-group-sm">
                                <select class="form-control form-control-sm" [(ngModel)]="record.id_user" name="id_user" #id_user="ngModel" required>
                                    <option *ngFor="let t of users" [ngValue]="t.id">{{t.username}}</option>
                                </select>
                                <div *ngIf="id_user.invalid && (id_user.dirty || id_user.touched)" class="alert alert-danger">
                                    <div *ngIf="id_user.errors.required">Campo obbligatorio.</div>
                                </div>
                                <div class="input-group-append">
                                    <select class="form-control form-control-sm" name="created_from" #created_from="ngModel" [(ngModel)]="record.created_from">
                                        <option *ngFor="let s of shops" [ngValue]="s.id">{{s.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

    </form>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>

