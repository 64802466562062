<section class="content mt-1">
    <ul class="nav nav-tabs mb-1" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==3" (click)="tab=3" data-toggle="tooltip" title="Fasi">
                <i class="fas fa-file"></i><small class="d-none d-sm-inline-block ml-2">FASI</small></a>
        </li>
        <li class="nav-item" *ngFor="let t of types">
            <a class="nav-link" [class.active]="tab==t.id" (click)="tab=t.id">
                <i class="fas fa-folder-open"></i><span class="d-none d-sm-inline-block ml-2">{{t.name}}</span></a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent" >
        <ng-container *ngFor="let t of types">
            <div class="tab-pane show active" *ngIf="tab==t.id">
                <app-dossierlist [mode]="mode" [id_type]="t.id" (selectedRecordOut)="recordSelected=$event" [modalWindow]="modalWindow" [mode]="mode"></app-dossierlist>
            </div>
        </ng-container>
        <div class="tab-pane show active" *ngIf="tab==3">
            <app-dossierphases></app-dossierphases>
        </div>
    </div>
</section>