import { CustomField } from "projects/core/src/include/structures";
import { Address } from "./address"
import {Contact} from "./contact";

export class Shops {
    id: number;
    id_address: number;
    id_type: number;
    addressItem:Address;
    name: string;
    address: string;
    city: string;
    zip: number;
    country: string;
    latitude:number;
    longitude:number;
    note:string;
    opening_time:string;
    closed_day:string;
    activity:string;
    status:number;
    customfields:CustomField[];
    productsIDS:number[];
    contacts:Contact[];
    id_inventory:number;
    params:any;


    getParam(paramname,defaultvalue=""){
        let params=JSON.parse(this.params);
        for(let p in params){
            if(p==paramname)
                return params[p];
        }
        return defaultvalue;
    }
}

