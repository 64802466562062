import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service'
import { FormValue } from 'projects/core/src/include/structures';
import { NewformComponent } from '../modules/newform/newform.component';

@Injectable({
  providedIn: 'root'
})
export class FormsValueService extends WSService<FormValue> {

  constructor() { super("formsvalue") };

  
  showNewForm(record:any,table:string,oncomplete:Function,id_form=0,id_formvalue=0,enableSignature=true,enableNotification=true,readonly=false,modalSize="",modalClass=""){
    Globals.modal.showModal(NewformComponent,[
      {"name":"table","value":table},
      {"name":"reference","value":record},
      {"name":"id_form","value":id_form},
      {"name":"id_formvalue","value":id_formvalue},
      {"name":"enableSignature","value":enableSignature},
      {"name":"enableNotification","value":enableNotification},
      {"name":"readonly","value":readonly}
  
    
    
    ],(instance)=>{
      if(instance!=null){
          oncomplete(instance['record']['id'],instance['record']);
      }
    },modalSize,modalClass);
  }
    

}
