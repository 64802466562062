<div class="row">
    <div class="col-12">
        <ul class="list-group list-group-flush">
            <li *ngFor="let f of files;let i=index" class="list-group-item">
                <div class="row border-bottom pb-2">
                    <div class="col-9">
                        <i data-toggle="tooltip" title="{{getExtension(f) | uppercase }}" class="text-gray fas fa-2x fa-file fa-file-{{getExtension(f)}}"
                            [class.fa-file-zipper]="getExtension(f)=='zip' || getExtension(f)=='rar'"
                            [class.fa-file-image]="getExtension(f)=='bmp' || getExtension(f)=='jpg' || getExtension(f)=='png'"
                            [class.fa-file-video]="getExtension(f)=='mov' ||getExtension(f)=='avi' || getExtension(f)=='mp4'"
                            [class.fa-file-audio]="getExtension(f)=='wav' ||getExtension(f)=='mp3' || getExtension(f)=='wma'"
                            [class.fa-file-word]="getExtension(f)=='doc' || getExtension(f)=='docx'"
                            [class.fa-file-excel]="getExtension(f)=='xls' || getExtension(f)=='xlsx'"
                            [class.fa-file-powerpoint]="getExtension(f)=='ppt' || getExtension(f)=='pptx'"
                            [class.fa-file-code]="getExtension(f)=='xml'"
                            [class.fa-file-signature]="getExtension(f)=='p7m'">
                        </i>&nbsp;
                        <ng-container *ngIf="!f['edit']" (click)="f['edit']=true">
                            <span data-toggle="tooltip" title="{{f.name}}" class="text-truncate d-inline-block" style="width:90%">{{f.name}}</span>
                        </ng-container>
                        <ng-container  *ngIf="f['edit']">
                            <input type="text" class="" autofocus name="{{i}}_{{f.file}}" [(ngModel)]="f.name" />
                            <br><small data-toggle="tooltip" title="{{f.file}}" class="text-truncate d-inline-block" style="width:90%">{{f.file}}</small>

                        </ng-container>
                    </div>
               
                    <div class="col-3 text-right">
                        <a class="btn btn-info btn-sm ml-1"  data-toggle="tooltip" title="Visualizza" (click)="downloadFile(f)"><i class="fa fa-eye"></i></a>
                        <a class="btn btn-secondary btn-sm ml-1"  data-toggle="tooltip" title="Rinomina" (click)="f.edit=!f.edit"><i class="fa fa-pencil-alt"></i></a>
                    
                        <a class="btn btn-danger btn-sm ml-1" data-toggle="tooltip" title="Elimina" (click)="removeFile(f)"><i class="fa fa-trash"></i></a>
                
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-12 text-center mt-1">
        <app-uploadfile [directory]="directory" (fileuploaded)="this.files.push($event);"></app-uploadfile>
        <!--
        <div class="m-auto p-2" style="width:60%;height:130px;color:#ccc;border: 1px dashed #ccc;border-radius:10px;cursor:pointer;">
            <i class="fas fa-2x fa-bullseye mt-2"></i><br>
            <i>Trascina e rilascia <b>qui</b>.</i><br>
            <a class="btn btn-secondary btn-sm mt-3" (click)="fileToUpload.click()"><i class="fas fa-upload mr-2"></i>Carica file</a>
        </div>
        <input type="file" style="display:block;opacity:0;width:60%;height:130px;margin:-130px auto 0;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
        -->
    </div>
</div>