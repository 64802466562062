import { Injectable } from '@angular/core';
import { Deadlines } from '../db/deadlines';

import { Observable } from 'rxjs';
import { WSService } from 'projects/core/src/include/service';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { DeadlinesModule } from '../views/deadlines/deadlines.module';
import { Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';

@Injectable({
  providedIn: 'root'
})

export class DeadlinesService extends WSService<Deadlines> {

  constructor() {super("deadlines") };


  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]):Observable<any>{
    return Globals.ws.requestUrl("deadlines","getTotals",this.getSQLWhere(filters,ordering,advancedFilter));
  }

  ///Verifica se è presente la scadenza in futuro
  nextDeadline(d:Deadlines){

    let today:Date=new Date();
    

    return  Globals.ws.requestUrl<Deadlines[]>("deadlines","getItems",["d.date>='"+Helper.convertDateControl(today)+"' AND d.id_type="+d.id_type.toString()+" AND d.table='"+d.table+"' AND id_table="+d.id_table.toString()]);
  }


  save(item:Deadlines,return_id:Function,checkdeadlinesandactions=true){
    super.save(item,(r_id)=>{
      return_id(r_id);
      Globals.events['savingDeadlineEvent'].emit();
      //DeadlinesModule.savingDeadlineEvent.emit();
    },checkdeadlinesandactions);
    
  }

  

  getItemsForProduct(id_product:number):Observable<Deadlines[]>{
    return Globals.ws.requestUrl<Deadlines[]>("deadlines","getItemsForProduct",[id_product.toString()]);

  }

  generateInstallments(start,end,amount,tax,amount_type,type,typedetail,id_type,id_table,table,description,id_address,params=null):Observable<any>{
    return Globals.ws.requestUrl("deadlines","generateInstallments",[start,end,amount,tax,amount_type,type,typedetail,id_type,id_table,table,description,id_address,params]);
  }
  

}

