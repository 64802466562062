<section class="content">
    <div class="">
        <ul class="nav nav-tabs mb-1" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#tab1CF" role="tab" aria-controls="tab1-CF" aria-selected="true">
                    <i class="fas fa-tasks"></i><span class="d-none d-sm-inline-block ml-2">Lista</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab2CF" role="tab" aria-controls="tab2-CF" aria-selected="false">
                    <i class="fas fa-equals"></i><span class="d-none d-sm-inline-block ml-2">Riepilogo</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab3CF" role="tab" aria-controls="tab2-CF" aria-selected="false">
                    <i class="fas fa-cash-register"></i><span class="d-none d-sm-inline-block ml-2">Stato cassa</span></a>
            </li>
        </ul> 
        <div class="tab-content p-1" id="myTabContent" >
            <div class="tab-pane fade show active" id="tab1CF" role="tabpanel" aria-labelledby="tab1-CF">
                <app-cash-flow></app-cash-flow>
            </div>
            <div class="tab-pane" id="tab2CF" role="tabpanel" aria-labelledby="tab2-CF">
                <app-cash-flow-overview></app-cash-flow-overview>
            </div>
            <div class="tab-pane" id="tab3CF" role="tabpanel" aria-labelledby="tab3-CF">
                <app-cashregister></app-cashregister>
            </div>
        </div>
    </div>
</section>