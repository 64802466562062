<app-windowlist [model]="this">
    <div class="col-lg-12" >
        <div class="card card-outline">
            <div class="p-0">
                <div class="row" >
                    <div class="col-9">
                        <div class="input-group input-group-info input-group-sm p-1">
                            <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="nome cliente o descrizione" [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                            <div class="input-group-append ">
                                <button type="button" class="btn btn-default" data-toggle="tooltip" title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''" data-toggle="tooltip" title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                            </div>
                        </div>
                    </div>

                    <div class="col-3">

                        <button  (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block"></span></button>
                        <button  (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block"></span></button>
                        <button (click)="openDetail(null)"  class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>

                    </div>
                </div>

                <div *ngIf="list==null" class="m-4">
                    <i class="fa-2x fas fa-info text-info mr-3"></i>
                    <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                </div>

                <form [formGroup]="form" *ngIf="list!=null">
                    <table class="table table-nowrap table-sm table-striped table-hover m-0 text-sm">
                        <thead class="bg-light">
                            <th width="20px">
                                <input #checkboxselectall type="checkbox"
                                (change)="onChangeAll($event.target.checked)" />
                            </th>
                            <th>Nome</th>
                            <th>Tabella di riferimento</th>
                            <th></th>
                        </thead>
                        <tbody>
                        
                            <!-- elemento ripetuto -->
                            <ng-container *ngFor="let record of list">
                                <tr>
                                    <td>
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}" data-toggle="tooltip" title="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                        />
                                    </td>
                                    <td>{{record.name}}</td>
                                    <td>{{record.table}}</td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="openDetail(record)" routerLinkActive="active" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                            <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                                  
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>



                </form>

            </div>

            <div class="card-footer clearfix py-1">
                <app-pagination [model]="this" ></app-pagination>
            </div>
        </div>
    </div>

</app-windowlist>
