    <div class="row">
        <div class="col-xs-12 col-sm-2 pt-2">
            <b><i class="fas fa-sliders-h text-gray mr-2"></i>Regole:</b>
        </div>
        <div class="col-xs-12 col-sm-9">
            <div *ngIf="filters">
                <div class="input-group input-group-sm my-1" *ngFor="let f of filters">
                    <div class="col-xs-1 col-sm-1">
                        <button data-toggle="tooltip" title="Elimina regola" class="btn btn-sm text-danger float-right" (click)="removeFilter(f)"><i class="fa fa-minus"></i></button>
                    </div>
                        <div class="col-xs-11 col-sm-4">
                            <select name="customfield" class="form-control form-control-sm" [(ngModel)]="f.customfield">
                                <ng-container *ngFor="let c of customfields">
                                    <ng-container *ngIf="c.type=='separator'">
                                        <optgroup label="{{c.label}}"></optgroup>
                                    </ng-container>
                                    <ng-container *ngIf="c.type!='separator'">
                                        <option  [ngValue]="c">{{c.label}}</option>
                                    </ng-container>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-xs-12 col-sm-3 input-group ">
                            <select name="condition" class="form-control form-control-sm text-info text-bold" [(ngModel)]="f.condition">
                                <option value="ugual">uguale</option>
                                <option value="major">maggiore</option>
                                <option value="majorugual">maggiore e uguale</option>
                                <option value="minor">minore</option>
                                <option value="minorugual">minore e uguale</option>
                                <option value="like">contiene</option>
                                <option value="notlike">non contiene</option>
                                <option value="null">non valorizzato</option>
                                <option value="notnull">valorizzato</option>
                            </select>
                        </div>
                        <div class="col-xs-12 col-sm-4" *ngIf="f.customfield">
                                <input *ngIf="f.customfield.type=='text' || f.customfield.type=='multi' || f.customfield.type=='tax_code'" type="text" name="value" class="form-control form-control-sm" [(ngModel)]="f.value" />
                                <input *ngIf="f.customfield.type=='date'" type="date" name="value" class="form-control form-control-sm" [(ngModel)]="f.value" />
                                <input *ngIf="f.customfield.type=='url'" type="url" name="value" class="form-control form-control-sm" [(ngModel)]="f.value" />
                                <select *ngIf="f.customfield.type=='checkbox'" name="value" class="form-control form-control-sm" [(ngModel)]="f.value">
                                    <option value="1">Si</option>    
                                    <option value="0">No</option>
                                </select>
                                <input *ngIf="f.customfield.type=='number'" type="number" name="value" class="form-control form-control-sm" [(ngModel)]="f.value" />

                                <div class="input-group" *ngIf="f.customfield.type=='currency'" >
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">&euro;</span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm"  min="0" step="0.01" name="value" placeholder="..." [(ngModel)]="f.value" >
                                </div>
                                <div *ngIf="f.customfield.type=='select'">
                                    <select class="form-control form-control-sm"  name="value" [(ngModel)]="f.value">
                                        <optgroup *ngIf="f.customfield.option!=null && f.customfield.option!=''">
                                            <option  *ngFor="let o of f.customfield.option" value="{{o.item}}">{{o.item}}</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <!--<td class="pt-0 border-0">
                                <select class="custom-select"  name="value" [(ngModel)]="f.nextCondition">
                                    <option value="INTERSECT"> E </option>
                                    <option value="UNION"> O </option>
                                </select>
                            </td>-->
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-1">
        <button data-toggle="tooltip" title="Aggiungi regola" class="btn text-info btn-sm my-1 float-right" (click)="addFilter()"><i class="fa fa-plus"></i></button>
    </div>
</div>
