import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Calendar, Tour } from 'projects/c1-backend/src/app/db/tour';
import { ToursService } from 'projects/c1-backend/src/app/services/tours.service';
import { Model } from 'projects/core/src/include/model';
import { Filter, FilterMode, ToastMode } from 'projects/core/src/include/structures';
import { Image } from 'projects/c1-backend/src/app/db/image';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Helper } from 'projects/core/src/helper';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { Meta } from '@angular/platform-browser';  



registerLocaleData(localeIt, 'it');
@Component({
  selector: 'app-tourpreview',
  templateUrl: './tourpreview.component.html',
  styleUrls: ['./tourpreview.component.css']
})
export class TourpreviewComponent extends Model<Tour> implements AfterViewInit {

  @ViewChild("modalTour")
  modalTour:TemplateRef<any>;
  modalTourRef;


  
  tab=1;
  locale: string = "it";


  date_from=new Date(this.today.getFullYear(), this.today.getMonth(), -7); 
  date_to=new Date(this.today.getFullYear(), this.today.getMonth()+1, 7); 
  date_selected=new Date();

  filter_status:Filter=new Filter();
  filter_search:Filter=new Filter();

  
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  tour_events=[];
  tour_selected:Tour;
  event_selected;
  total=0;

  get event_partecipants_available():number{
    if(this.event_selected)
      return this.event_selected.meta.quantity-this.event_selected.meta.busy;
    else
      return 0;

  }

  constructor(
    private toursService: ToursService,
    private route1: ActivatedRoute,
    private modalService:NgbModal,
    private meta: Meta
    ) { 
    super(route1,toursService);
    
  }

  get currentUser(){
    return Globals.user;
  }

  ngAfterViewInit(): void {
    this.record=new Tour();
    this.title="Tour";
    super.ngOnInit.apply(this, arguments);

    this.afterGetItem=(()=>{

      if(this.id==0){
        this.record.id=0;
        this.record.images=[];
        this.record.dates=[];
      }else{
        this.getDates();
        this.meta.addTags([
          { name: 'description', content: this.record.intro },
          { property: 'og:description', content: this.record.intro },
  
        ]);  
      }
      
      //parserizza le immagini
      try{
        let images=JSON.parse(this.record.images.toString());
        this.record.images=[];
        for(let i of images){
          let img=new Image();
          Object.assign(img,i);
          this.record.images.push(img);
        }
          
      }catch{
        this.record.images=[];
      }

      

      this.record.parseFeatures();
      window.history.replaceState("",this.record.code,"/tour/"+this.record.code);

    });

    
    

    this.getItem();
  }

  close(){
    if(this['modalWindow'])
      this['modalWindow'].close();
  }

  getDates(){

    const start=Helper.convertDateControl(this.date_from);
    const end=Helper.convertDateControl(this.date_to);

    this.toursService.getDate(this.record.id,start,end,"").subscribe((items)=>{
      this.record.dates=items;
      
      this.events=[];
      for(let d of items){
        
          let c:CalendarEvent={} as CalendarEvent;
          c.start=new Date(d.date);
          c.title="Tour";          
          c.meta=d;
          this.events.push(c);
        
        
        
  
      }

    });
  }


  getCoverImage(r:Tour){
    
   let t:Tour=new Tour();
   Object.assign(t,r);
   t.parseImages();
   return t.coverImage().imagePath;
    
  }


  dayClicked(d:any): void {

    this.date_selected=d.date;
    this.tour_events=[];
    
    this.event_selected=null;
    this.tour_selected=null;

    if(d.events.length>0){
      //verifica che non è possibile prenotare prima di oggi
      var today=new Date();
      var today_date=new Date(today.getFullYear(),today.getMonth(),today.getDate(),0,0,0);
      if(this.date_selected<today_date){
        Globals.message.showToaster("Non è possibile effettuare una prenotazione prima di oggi", ToastMode.DANGER);
        return;
      }

      
      //verifica che ci sono eventi futuri
     
      for(let e of d.events){
        var date:Date=new Date(e.meta.date+" "+e.meta.time_start);
        
        if(date>today){
          this.tour_events.push(e);
        }
      }

      if(this.tour_events.length>0){
        this.event_selected=this.tour_events[0];
        this.onSelectEvent(this.tour_events[0]);
        this.openModalTour();
      }
      else{
        Globals.message.showToaster("Non ci sono eventi prenotabili per questa data", ToastMode.DANGER);
        return;
      }
    }
      
 
  }



  onSelectEvent(event){
    let tourcalendar=new Calendar();
    Object.assign(tourcalendar,event.meta);
    tourcalendar.parsePrices();
    tourcalendar.adults=1;
    tourcalendar.children=0;
    tourcalendar.getPrice();


    
    


    this.tour_selected=new Tour();
    this.tour_selected.id=this.record.id;
    this.tour_selected.title=this.record.title;
    this.tour_selected.subtitle=this.record.subtitle;
    this.tour_selected.images=this.record.images;
    this.tour_selected.description=this.record.description;
    this.tour_selected.id_shop_start=this.record.id_shop_start;
    this.tour_selected.details=this.record.details;
    this.tour_selected.dates=[];
    this.tour_selected.dates.push(tourcalendar);
    this.calculateTotal();

  }


  handleEvent(d:any) {
    const startDate=new Date(d);
    this.date_from=new Date(startDate.getFullYear(), startDate.getMonth(), -7); 
    this.date_to=new Date(startDate.getFullYear(), startDate.getMonth()+1, 7); 

    this.getDates();

   }

   calculateTotal(){
    this.tour_selected.dates[0].getPrice();
    this.total=this.tour_selected.dates[0].adults*this.tour_selected.dates[0].price_adult+this.tour_selected.dates[0].children*this.tour_selected.dates[0].price_children;
   
    //verifica il numero massimo di partecipanti
    
  
  }


   confirm(){
     this['modalWindow'].close("success");
     this.closeModalTour();
   }

   openModalTour(){
     this.modalTourRef=this.modalService.open(this.modalTour);

   }

   closeModalTour(){
     this.modalTourRef.close("success");
   }

}
