import { Injectable } from '@angular/core';
import {User,ToastMode } from '../include/structures';
import { HttpClient,HttpHeaders,HttpErrorResponse, HttpParams} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {Observable, of,throwError} from 'rxjs';
import { Globals } from '../globals';
import * as CryptoJS from 'crypto-js';

export interface SaveResult{
  value:number;
};

@Injectable({
  providedIn: 'root'
})


export class WebserverService {

  serverKey="5f2b5cdbe5194f10b3241568fe4e2b24";
  base:string;
  private token:string="";

  public static showMessage=true;

  constructor(private http: HttpClient) { 

  }


  

  
  getToken(username:string,password:string,onGetTokenComplete,showMessage=true):void{
    //ottieni il token
    WebserverService.showMessage=showMessage?showMessage:true;
    
    const cpassword=this.encode(password,this.serverKey);


    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-C1-getToken':"true",
        'X-C1-Username':username,
        'X-C1-Password':cpassword,
     
      })
    }

    var url=this.base;
    //var url=this.base+"?getToken&username="+username+"&password="+password;
    this.http.get<string>(url,httpOptions).pipe(catchError(this.handleError)).toPromise<string>().then((value)=>{
      if(value.hasOwnProperty("error")){
        onGetTokenComplete(false);
      }
      if(value.hasOwnProperty("token")){
        this.token=value['token'];
        Globals.user=new User();
        
        Object.assign(Globals.user,value['user']);
        Globals.user.setParams();
        //AppComponent.user=value['user'];
        onGetTokenComplete(true);
      }

      
    });
  }

  private encode(text, skey) {
    var encoded = "";
    for (var i=0; i<text.length;i++) {
        var a = text.charCodeAt(i);
        var b = a ^ 123;    // bitwise XOR with any number, e.g. 123
        encoded = encoded+String.fromCharCode(b);
    }
    return btoa(encoded);
}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`);
    }
    if(WebserverService.showMessage)
      switch(error.status){
        case 0:
          Globals.message.showToaster("Impossibile raggiungere il server",ToastMode.DANGER);
          break;
      default:
          Globals.message.showToaster("Errore durante la connessione al server",ToastMode.DANGER);
          break;
    }

    
    Globals.setLoading(false);

    return throwError(
      error.error);
    
  }

  requestUrl<T>(model:string,task:string,args:string[],nokeyvalue:boolean=true,paging_start:number=0,paging_count:number=10,onerror:Function=null){

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-C1-Model':model,
        'X-C1-Task':task,
        'X-C1-Args':btoa(unescape(encodeURIComponent(args.join("|")))).replace('+','xMl3Jk').replace('/','Por21Ld'),
        'X-C1-Token':this.token

      }),
      params: new HttpParams()
                              .set('structuredOutput',nokeyvalue==false?"true":"false")
                              .set('paging_start',paging_start.toString())
                              .set('paging_count',paging_count.toString())
                              .set('id_user',Globals.user!=undefined?Globals.user.id.toString():"")
                              .set('created_from',((Globals.user!=undefined && Globals.user['shop'])?Globals.user['shop']['id']:""))
                        
       
    };


     
    //crea chiave random per risolvere problemi di cache
    //let r=Math.random().toString()+new Date();
    

    //var url=this.base+"?"+(nokeyvalue==false?"":"no_key_value=true&")+"param_model="+model+"&param_task="+task+"&paging_start="+paging_start.toString()+"&paging_count="+paging_count.toString()+(Globals.user!=undefined?"&id_user="+Globals.user.id:"")+((Globals.user!=undefined && Globals.user['shop'])?"&created_from="+Globals.user['shop']['id']:"")+"&token="+this.token+"&args="+btoa(unescape(encodeURIComponent(args.join("|")))).replace('+','xMl3Jk').replace('/','Por21Ld');
    var url=this.base; //+"?r="+r;
    //console.log(model+"|"+task+"|");
    //console.warn(url);
    
    return this.http.get<T>(url,httpOptions ).pipe(catchError(this.handleError));
    
  }


  

  send<T>(model:string,task:string,item:T,args:string[]=[],oncomplete:Function,message:string="",file:File=null){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-C1-Token':this.token,
        'X-C1-Model':model,
        'X-C1-Task':task,
        'X-C1-Args':btoa(unescape(encodeURIComponent(args.join("|")))).replace('+','xMl3Jk').replace('/','Por21Ld')

      })
    }


    const formData = new FormData();

    //formData.append("token",this.token);
    //formData.append("param_model",model);
    //formData.append("param_task",task);
    if(Globals.user){
      if(Globals.user.id)
        formData.append("id_user",Globals.user.id.toString());
      if(Globals.user['shop'])
        formData.append("created_from",Globals.user['shop']['id'].toString());
    
      }
    
    /*if(args.length>0)
      formData.append("args",btoa(args.join("|")));
    */
    if(item!=null){
      if(Array.isArray(item)){
        let p:string;
        p=JSON.stringify(item);
        formData.append("multiple",p);
      
      }else{
        var made = Object.getOwnPropertyNames(item);

        for (var i of made) { 
          if(item[i]!=null){
            let p:string;
            if(Array.isArray(item[i]))
              p=JSON.stringify(item[i]);
            else{
              switch(typeof(item[i])){
                case 'boolean':
                  p=item[i]==true?"1":"0";
                  break;
                case 'object':
                  p=JSON.stringify(item[i]);
                  break;
                default:
                  p=item[i];
                  break;
              }

              
              
            }
            formData.append("field["+i+"]",p);
          }
        }
    }
  }
    
   if(file!=null){
     formData.append("file",file,file.name);
   }
    

    var url=this.base;
    
    
    this.http.post(url,formData,httpOptions).subscribe((res)=>{

      let id:number=(res as SaveResult).value

      oncomplete(id);
    },(err)=>{
      Globals.message.showToaster("Errore nel salvataggio",ToastMode.DANGER);
      console.log(err);
    },
    ()=>{
      if(message!="")
        Globals.message.showToaster(message,ToastMode.SUCCESS);
     
     
    }
    );

    
  }


  

}
