import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { ModelList } from 'projects/core/src/include/modellist';
import { ToastMode } from 'projects/core/src/include/structures';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-documentssdi',
  templateUrl: './documentssdi.component.html',
  styleUrls: ['./documentssdi.component.css']
})
export class DocumentssdiComponent extends ModelList<any> implements OnInit {

  list:any;
  constructor(
    private fb1: FormBuilder, 
    private documentsService:DocumentsService
  ) {
    super(documentsService,fb1);
   }

  ngOnInit(): void {
    Globals.loading=true;
    this.documentsService.getDocumentsSdi().subscribe((items)=>{
      this.list=items;
      Globals.loading=false;
    });
  }

  downloadDocument(sdi_idinvoice){
    Globals.loading=true;
    this.documentsService.downloadDocumentSdi(sdi_idinvoice).subscribe((result)=>{
      if(result){
        Globals.message.showToaster("Documento scaricato correttamente!",ToastMode.SUCCESS);
      }else{
        Globals.message.showToaster("Errore durante lo scaricamento del documento!",ToastMode.DANGER);
      }
      Globals.loading=false;
      this.cancel();
    });
  }

  downloadDocumentSelected(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }
    let ids=this.form.value.id;
    if(confirm("Sicuro di voler scaricare i documenti selezionati dallo SDI? L'operazione potrebbe durare alcuni minuti")){
      
      Globals.message.showToaster("Importazione documenti dallo SDI avviata",ToastMode.WARNING);
      this.documentsService.downloadDocumentsSdi(ids.toString()).subscribe(()=>{
       
        Globals.message.showToaster("Importazione documenti dallo SDI completata",ToastMode.SUCCESS);
        
      });

      this.cancel();
    }
  }

  cancel(){
    this['modalWindow'].close();
  }
}
