import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { MethodPayment } from '../db/methodpayment';

@Injectable({
  providedIn: 'root'
})
export class MethodpaymentsService extends WSService<MethodPayment> {

  constructor() { super("methodpayments")}

  getMethods():Observable<any[]>{
    return Globals.ws.requestUrl("methodpayments","getItems",[]);

  }
}
