import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { LoginventoryComponent } from '../loginventory/loginventory.component';


@Component({
  selector: 'app-historystock',
  templateUrl: './historystock.component.html',
  styleUrls: ['./historystock.component.css']
})
export class HistorystockComponent implements OnInit {

  @Input()
  id_product:number=0;

  list=[];
  chartData=[];
  chartLabels=[];
  chartOptions = {
    responsive: true
  };

  constructor(
    private inventoriesDetailService:InventoriesDetailsService
  ) { }

  ngOnInit(): void {
    this.getItems();
  }

  getItems(){
    this.inventoriesDetailService.getStockPeriod(this.id_product).subscribe((items)=>{
      this.list=items;
      let data=[];
      for(let i of items){
        data.push(i.stock);
        this.chartLabels.push(i.date);
      }
      
      let cdata={"data":data,"label":"Giacenza"};
      this.chartData.push(cdata);

    });
  }


  openStock(date){
    Globals.modal.showModal(LoginventoryComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_product","value":this.id_product},
      {"name":"date_from","value":Helper.convertString2Date(date)},
      {"name":"date_to","value":Helper.convertString2Date(date)},
      {"name":"filterbox","value":false},
    
    ],()=>{

    });
  }

}
