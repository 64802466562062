<section class="content">
    <div class="pl-1">
        

        <ul class="nav nav-tabs" id="inventoriesTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==0"  (click)="tab=0;">
                    <i class="fas fa-tasks"></i><span class="d-none d-sm-inline-block ml-2">Stato prodotti</span></a>
            </li>

            <!--<li class="nav-item">
                <a class="nav-link" id="tab-inventory3" data-toggle="tab" href="#tabinventory3" role="tab" aria-controls="home" aria-selected="true">
                    <i class="fas fa-tasks"></i><span class="d-none d-sm-inline-block ml-2">Stato magazzini</span></a>
            </li>-->
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==1" (click)="tab=1;"  >
                    <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Cronologia</span></a>
            </li>

            <li class="nav-item" *ngIf="id_product==0">
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2;"  >
                    <i class="fas fa-calculator"></i><span class="d-none d-sm-inline-block ml-2">Giacenze</span></a>
            </li>

            <li class="nav-item" *ngIf="id_product>0">
                <a class="nav-link" [class.active]="tab==5" (click)="tab=5;"  >
                    <i class="fas fa-calculator"></i><span class="d-none d-sm-inline-block ml-2">Andamento giacenza</span></a>
            </li>

            
            
        </ul> 

        <div class="tab-content p-1" id="inventoriesTabContent" >
            <div class="tab-pane" [class.active]="tab==0" *ngIf="tab==0">
                <app-logstock [mode]="mode" [id_product]="id_product" [sn]="sn"></app-logstock>
            </div>
           
            
            <div class="tab-pane" [class.active]="tab==1" *ngIf="tab==1">
                <app-loginventory [mode]="mode" [id_product]="id_product" [sn]="sn"></app-loginventory>
            </div>

            <div class="tab-pane" [class.active]="tab==2" *ngIf="tab==2">
                <app-history [mode]="mode" [id_product]="id_product" [sn]="sn"></app-history>
       
            </div>

            <div class="tab-pane" [class.active]="tab==4" *ngIf="tab==4">
                <app-stateinventories [mode]="mode" [id_product]="id_product" [sn]="sn"></app-stateinventories>
            </div>

            <div class="tab-pane" [class.active]="tab==5" *ngIf="tab==5">
                <app-historystock [mode]="mode" [id_product]="id_product" [sn]="sn"></app-historystock>
            </div>

            
        </div>

      
    
    </div>
</section>

