import { Component, Input, OnInit } from '@angular/core';
import {Modal} from '../../include/modal';
import {Template} from '../../include/structures';
import {TemplatesService} from '../../lib/templates.service';
@Component({
  selector: 'app-selecttemplate',
  templateUrl: './selecttemplate.component.html',
  styleUrls: ['./selecttemplate.component.sass']
})
export class SelecttemplateComponent extends Modal implements OnInit {

  @Input()
  templates:Template[]=[];
  selectedTemplate:Template;
  table:string;

  arguments:string[];
  detail:boolean=false;

  constructor(private templatesService: TemplatesService) { super() }

  ngOnInit(): void {
    if(this.templates==null || this.templates.length==0){
      this.templatesService.getAllTemplates(this.table).subscribe((items)=>{
        this.templates=items;
        if(this.templates.length>0)
          this.selectedTemplate=this.templates[0];
      });
    }


  }

  getTemplate(template){
    
      this.selectedTemplate=template;
      this['modalWindow'].close('success');
       
      

    
  }

}
