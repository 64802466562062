import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModelList } from '../../include/modellist';
import { CustomFunction, Filter, FilterMode } from '../../include/structures';
import { CustomfunctionsService } from '../../lib/customfunctions.service';
import { CustomFunctionsDetailComponent } from './detail/detail.component';

@Component({
  selector: 'lib-customfunctions',
  templateUrl: './customfunctions.component.html',
  styleUrls: ['./customfunctions.component.css']
})
export class CustomfunctionsComponent extends ModelList<CustomFunction> implements OnInit {

  filter_type:Filter=new Filter();


  constructor(
    private fb1: FormBuilder,
    private customfunctionService: CustomfunctionsService,
  ) {
    super(customfunctionService,fb1);
    this.detailViewModal=CustomFunctionsDetailComponent;
   }

  ngOnInit(): void {

    this.title='Funzioni personalizzate';
    super.ngOnInit.apply(this, arguments); 


    this.filter_type.mode=FilterMode.normal;
    this.filter_type.fields=[];
    this.filter_type.fields.push("c.table");
    this.filter_type.value="";
    this.filter.push(this.filter_type);


    this.getItems();

  }


  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }


}
