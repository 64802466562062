import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModelList } from 'projects/core/src/include/modellist';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TemplateEmailService } from '../../lib/template-email.service';
import { TemplateEmail } from '../../include/structures';

@Component({
  selector: 'app-template-email',
  templateUrl: './template-email.component.html',
  styleUrls: ['./template-email.component.css']
})

export class TemplateEmailComponent extends ModelList<TemplateEmail> implements OnInit {

  public Editor = ClassicEditor;
  editorOn=true;

  constructor(
    private templateEmailService: TemplateEmailService,
    private fb1: FormBuilder,
  ) {
    super(templateEmailService,fb1);
   }

  ngOnInit(): void {
    this.title='Template email';
    super.ngOnInit.apply(this, arguments); 
    this.getItems();
   
  }

 
  
  setRecord(record:TemplateEmail){
    this.selectedRecord=new TemplateEmail();
    Object.assign(this.selectedRecord, record);
    

  }

  confirmRecord(){
    this.templateEmailService.save(this.selectedRecord,(id)=>{
      this.getItems();
    });
  }

  add(){
    this.selectedRecord=new TemplateEmail();
    
  }

  
  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }



}
