import { Component, Input, OnInit } from '@angular/core';
import { json } from 'mathjs';
import { TakephotoComponent } from 'projects/core/src/common/takephoto/takephoto.component';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { UploadFileComponent } from 'projects/core/src/common/upload-file/upload-file.component';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { Image } from '../../../db/image';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {

  @Input()
  field:any=null;

  @Input()
  record:any; //record necessario per la parserizzazione delle keyword 

  @Input()
  disabled:boolean=false;
  
  constructor(private uploadService:UploadService) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(){
    if(this.field.value=="" || this.field.value==null || this.field.value==undefined){
      if (this.field.defaultvalue!=""){
        this.field.value=this.field.defaultvalue;
      }
    }
  }

  getBasePath(path){
    if(path)
      return Globals.config.serverUrl+"/"+path;
    return "";
  }

  parseKeyword(value){
    if(this.record)
      return Helper.replaceKeyword(value,this.record);
    else
      return value;
  }

  downloadFile(relative_path){
    window.open( this.getBasePath(relative_path),"_blank");
  }

  uploadFile(){
    Globals.modal.showModal(UploadFileComponent,[
      {"name":"record","value":this.record},
      {"name":"params","value":this.field.params},

    ],(instance)=>{

      if(instance){
        this.field.value=instance['filename'];
      }
    },"md");
  }

  pathImage(relative_path){
    if(relative_path==""){
      return "assets/placeholder_photo.jpg";
    }else{
      return this.getBasePath(relative_path);
    }
  }

  addPhoto(field){
    Globals.modal.showModal(TakephotoComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance){
        this.uploadService.uploadImage64(instance.imageCaptured,"ads",(result)=>{
          if(!Array.isArray(field.value))
            field.value=[];
          
          field.value.push(result);
          
        },true);
      }
    });
  }
  takePhoto(field){
    Globals.modal.showModal(TakephotoComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance){
        this.uploadService.uploadImage64(instance.imageCaptured,"ads",(result)=>{
          field.value=result;
        },true);
      }
    });
  }


  isPhotoMultiple(field){
    if(!Array.isArray(field.params)){
      try{
        field.params=JSON.parse(field.params);
   
      }catch{
        field.params=[];
      }
    }


    if(field.params.length>0){
      for(let p of field.params){
        if(p.name=="multiple"){
          return p.value;
        }
      }
    }
    return false;
  }
  parseMultiplePhoto(value:any){
    if(!Array.isArray(value)){
      try{
        return JSON.parse(value);
  
      }catch{
        return [];
      }
    }

    return value;
  }
  deletePhoto(field,imgPath){
    if(confirm("Sicuro di voler eliminare la foto?")){
      if(!Array.isArray(field.value)){
        field.value=JSON.parse(field.value);
      }

      for(let i=0;i<field.value.length;i++){
        if(field.value[i]==imgPath){
          field.value.splice(i,1);
          return;
        }
      }
    }
  }

}
