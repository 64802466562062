import { Component, OnInit,Input ,Output,EventEmitter, ViewChild,TemplateRef} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ProductCategories } from '../../db/productcategories';
import { ProductcategoriesService } from '../../services/productcategories.service';

@Component({
  selector: 'app-treecategories',
  templateUrl: './treecategories.component.html',
  styleUrls: ['./treecategories.component.css']
})
export class TreecategoriesComponent implements OnInit {

  @ViewChild('detailModal')
  detailModal:TemplateRef<any>;

  @Input()
  list:any[]=[]

  @Input()
  editButton:boolean=true;
  @Input()
  showAll:boolean=false;
  @Input()
  class="";
  @Input()
  showCountProduct:boolean=true;
  @Input()
  id:number=0;

  @Input()
  multiple:boolean=false;

  @Output()
  id_selected:EventEmitter<any>=new EventEmitter();

  ids=[];
  categories:ProductCategories[];

  onSaving:Function;
  recordSelected:ProductCategories;

  constructor(private modalService: NgbModal,
    private productcategoriesService:ProductcategoriesService,

    ) { }

  ngOnInit(): void {
  }

  edit(record:ProductCategories){

    this.productcategoriesService.getCategories().subscribe((items)=>{
      this.categories=items;
    
      this.recordSelected=record;
      this.modalService.open(this.detailModal);
    });

  }

  save(){
    this.productcategoriesService.save(this.recordSelected,()=>{
      if(this.onSaving)
        this.onSaving();
    });
  }

  add(){
    this.recordSelected={} as ProductCategories;
    this.recordSelected.id=0;
    this.recordSelected.parent=0;
    this.edit(this.recordSelected);

  }

  delete(record:ProductCategories){
    if(confirm("Sicuro di voler eliminare la categoria? N.B. Procedendo anche tutte le categorie collegate ad essa saranno eliminate")){
      this.productcategoriesService.delete([record.id.toString()]).subscribe(()=>{

      });
    }
  }

  OnSelect(event){

    if(this.multiple){
      //verifica se l'id è stato già inserito
      let i=this.ids.indexOf(event);
      if(i>-1){
        this.ids.splice(i,1);
       
      }else{
        this.ids.push(event);
        this.selectChildren(event);
      }
      
      
      
      
      
      this.id_selected.emit(this.ids);

      



    }else{
      this.ids=[];
      this.ids.push(event);
      this.id_selected.emit(this.ids[0]);
    }

    
  }

  selectChildren(id){
    //verifica se il nodo ha dei figli
    for(let n of this.list){
      if(n.parent==id){
        this.ids.push(n.id);
        this.selectChildren(n.id);
      }
    }
  }

  checkSelected(id){
    return this.ids.indexOf(id)>-1?true:false;
  }

}
