import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { Brand } from '../db/brand';

import { WSService } from 'projects/core/src/include/service'
@Injectable({
  providedIn: 'root'
})
export class ProductBrandsService extends WSService<Brand> {

  constructor() { super("productbrands")};

  getBrands(id_category:number=0):Observable<Brand[]>{
    return  Globals.ws.requestUrl<Brand[]>("productbrands","getItems",id_category==0?[]:["id_category="+id_category.toString()]);
    
  }
}
