import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { WSService } from '../include/service';
import {Template} from '../include/structures';
@Injectable({
  providedIn: 'root'
})
export class TemplatesService extends WSService<Template>{

  constructor() { super("templates")}

  getTemplates(table:string,isdetail:boolean,inrow:boolean=false):Observable<Template[]>{
    //return  Globals.ws.requestUrl<Template[]>("templates","getItems",["t.table='"+table+"' AND t.detail="+(isdetail?"1":"0")+" AND t.inrow="+(inrow?"1":"0")]);
    return  Globals.ws.requestUrl<Template[]>("templates","getItems",["t.table='"+table+"' AND t.inrow="+(inrow?"1":"0")]);
    
  }

  getAllTemplates(table:string=""):Observable<Template[]>{
    return  Globals.ws.requestUrl<Template[]>("templates","getItems",[(table!=""?"t.table='"+table+"' AND ":"")+"t.status=1"]);
    
  }

  openTemplate(id_template:number,args:string):Observable<string>{
    
    return  Globals.ws.requestUrl<string>("templates","createTemplate",[id_template.toString(),args]);
   
  }


}
