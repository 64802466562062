import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { DocumentDetail } from '../../db/documents';
import { Product } from '../../db/product';
import { DocumentsDetailsComponent } from '../../views/documents/documents-details/documents-details.component';
import { LoginventoryComponent } from '../../views/inventories/loginventory/loginventory.component';
import { LogstockComponent } from '../../views/inventories/logstock/logstock.component';
import { ShowserialsComponent } from '../../views/inventories/showserials/showserials.component';
import { ProductsDetailsComponent } from '../../views/products/products-details/products-details.component';
import { MoveinventoryComponent } from '../moveinventory/moveinventory.component';

@Component({
  selector: 'app-productitem',
  templateUrl: './productitem.component.html',
  styleUrls: ['./productitem.component.css']
})
export class ProductitemComponent implements OnInit {

  @ViewChild("modalDocument")
  modalDocument:TemplateRef<any>;

  @ViewChild("modalInventories")
  modalInventories:TemplateRef<any>;


  document:Document;

  @Input()
  product:Product;

  @Input()
  showNotes:boolean=true;

  @Input()
  sn:string;

  @Input()
  id_document:number=0;

  @Output()
  OnSelected:EventEmitter<number>=new EventEmitter();
  

  isInventoriesEnable=false;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.isInventoriesEnable=Globals.parameters.get("inventories")?true:false;
  }

  showSerials(record){
    Globals.modal.showModal(ShowserialsComponent,[{"name":"product","value":record}],()=>{

    },"md");
  }

  selected(){
    this.OnSelected.emit(this.product.id);
  }

  openDocument(doc){
    this.document=doc;
    this.modalService.open(this.modalDocument);
  }

  openInventories(doc){
    Globals.modal.showModal(LoginventoryComponent,[{"name":"id_product","value":this.product.id}],()=>{})
    //Globals.modal.showModal(MoveinventoryComponent,[{"name":"id_product","value":this.product.id}],()=>{})
    //this.modalService.open(this.modalInventories);
  }

  openProduct(id_product){
    Globals.modal.showModal(ProductsDetailsComponent,[{"name":"mode","value":"modal"},{"name":"id","value":id_product}],(instance)=>{
     
    });
  }
}
