import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CashRegister } from 'projects/c1-backend/src/app/db/cashregister';
import { CashregisterService } from 'projects/c1-backend/src/app/services/cashregister.service';
import { ShopsService } from 'projects/c1-backend/src/app/services/shops.service';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Model } from 'projects/core/src/include/model';
import { User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';

@Component({
  selector: 'app-cashregister-detail',
  templateUrl: './cashregister-detail.component.html',
  styleUrls: ['./cashregister-detail.component.css']
})
export class CashregisterDetailComponent extends Model<CashRegister> implements OnInit {

  shops=[];
  users:User[];

  constructor(
    private route1: ActivatedRoute,
    private cashregisterService: CashregisterService,
    private shopsService:ShopsService,
    private userService:UserService,
  ) { 
    super(route1,cashregisterService); 
  }

  ngOnInit(): void {

    this.title = 'Cassa Dettaglio';
    
    if(this.mode!="modal")
      this.route1.params.subscribe((params) =>{

      this.id = params.id;
      
      } );

      const default_address=Globals.parameters.get("general").getParam("default_address");

      this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
        this.shops=items;
      });
      
      this.userService.getUsers().subscribe((items)=>{
        this.users=items;
        this.getItem();
      });

   
   this.afterGetItem=(()=>{
    if(this.record.id==0 || this.record.id==undefined){
      
      this.newRecord();
    }
   });

  }

  newRecord(){
    
   
    this.record.date=Helper.convertDateControl();
    this.record.id_user=Globals.user.id;
    this.record.created_from=Globals.user['shop']?Globals.user['shop']['id']:(this.shops.length>0?this.shops[0].id:0);
    this.cashregisterService.getCountPayment(Helper.convertDateControl(),this.record.created_from).subscribe((result)=>{
      this.record.amount=result['paid'];
      this.record.paid=this.record.amount;
    });
    
  }

}
