

<div class="wrapper" *ngIf="currentIsLogin==true">
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-light border-bottom-0 fixed-top elevation-1 pt-1">
    <!-- Left navbar links -->
    <ul class="navbar-nav" *ngIf="menu.length>0">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" ><i class="fas fa-bars pt-1"></i></a>
      </li>
    </ul>
    <div class="navbar-nav" >
      <h5 class="text-secondary m-0" *ngIf="history.length > 0"><b>{{history[history.length-1].label}}</b></h5>
    </div>
    
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">

       <!-- Connector -->
       <li class="nav-item text-center pt-2 mx-3" style="color: rgba(0, 0, 0, 0.5)" data-toggle="tooltip" title="C1 Connector" *ngIf="enableC1Connector">          
        <i [class.text-success]="c1connector_status" class="fas fa-network-wired"></i><br>
          <span class="float-left" style="font-size:7pt">{{c1connector_c1connectorid}}</span>
      </li>
     
      <!-- User login -->
      <li class="nav-item dropdown" *ngIf="true">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="fas fa-user-tie mr-2"></i>
          <span data-toggle="tooltip" title="{{currentUser.name}}" class="d-none d-sm-inline-block">{{currentUser.name}} </span><br/>
          <small style="max-width:120px; font-size:7pt" class="d-none d-sm-inline-block float-left text-uppercase text-truncate">{{currentUser.role}}</small>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right pt-0">
          <span data-toggle="tooltip" title="{{currentUser.name}}" class="d-sm-none dropdown-item dropdown-header text-uppercase bg-secondary">
            <b style="max-width:150px;" class="mb-0 text-truncate">{{currentUser.name}}</b><br>
          </span>
          <a *ngIf="enableSelectPoint" (click)="openSelectPoint()" class="dropdown-item" style="cursor:pointer;"><i class="fas fa-map-marker-alt mr-2"></i>Cambia punto vendita</a>
          <a (click)="logout()" class="dropdown-item"  style="cursor:pointer;"><i class="fas fa-sign-out-alt mr-2"></i>Esci</a>
        </div>
      </li>

     

      <!-- Notifiche -->
      <li class="nav-item dropdown">
        <app-alarm></app-alarm>
      </li>

      <!-- Impostazioni -->
      <li class="nav-item dropdown" *ngIf="menu_settings.length>0" >
        <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
          <i class="fas fa-cog"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0">
          <span class="dropdown-item dropdown-header bg-primary text-center" >Impostazioni</span>
          <a *ngFor="let m of menu_settings"  (click)="goToView(m)" class="btn btn-light dropdown-item">
            <i class="{{m.icon}} mr-2"></i>{{m.label}}
          </a>  
        </div>
      </li>


    </ul>
  </nav>
  <!-- /.navbar -->

  <!-- Main Sidebar Container -->
  <aside  class="main-sidebar sidebar-dark-primary position-fixed" style="background-color: #333;">
    <!-- Brand Logo -->
    <p href="#" class="brand-link mb-0">
      <img *ngIf="config.softwareicon" class="brand-image" src="{{config.softwareicon}}">
      <span class="ml-1 brand-text text-white">{{config.softwarename}}</span>
     
    </p>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column nav-compact" data-widget="treeview" role="menu" data-accordion="false"> <!-- per comprimere: nav-compact -->
            <li class="nav-item" *ngFor="let m of menu" >
                <a (click)="goToView(m);" [class.active]="m==current_module" data-widget="pushmenu" class="nav-link">
                  <i class="nav-icon {{m.icon}} mr-2"></i>
                  <p>{{m.label}}</p>
                </a>
              </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
  <div id="sidebar-overlay">
    <a class="close_sidebar d-block d-lg-none" data-widget="pushmenu" href="#" ><i class="fas fa-times"></i></a>
  </div>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper p-md-1" style="margin-top:56px;">
    
    
      <router-outlet></router-outlet>
      <div>
        <!-- Use ng-template to ensure that the generated components end up in the right place -->
        <ng-template #mainContent>
  
        </ng-template>
      </div>

    
  </div>
  <!-- /.content-wrapper -->
  
  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-light">
    <!-- Control sidebar content goes here -->
    <div class="p-3 control-sidebar-content">
      
    </div>
  </aside>
  <!-- /.control-sidebar -->

  <!-- Main Footer width: calc(100% - 250px); -->
  <footer class="main-footer fixed-bottom py-0 text-xs">
    <div class="float-left mx-2"><span class="text-primary"><b>C1</b></span></div>
    <div class="float-left mr-5">version <span class="text-primary">{{config.softwareversion}}</span></div>
    <div class="float-left mr-5 d-none d-lg-block">is a <a href="http://codingict.it" target="_blank">Coding</a> software</div>
    <!-- <div class="float-right d-none d-sm-inline-block ml-5">License:&ensp;<span data-toggle="tooltip" title="Server: {{config.serverUrl}}" class="text-primary">{{company.name}}<span *ngIf="company.vat_number"> (P.IVA: {{company.vat_number}})</span></span></div> -->
  </footer>

 

</div>



<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>