    <ng-container *ngIf="product">
        <div class="modal-header">
            {{product.brand}} {{product.name}}
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancel()" >
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    
                    <table class="table table-responsive  table-sm table-head-fixed table-hover m-0 text-sm">
                        <colgroup>
                            <ng-container *ngFor="let s of stocks">
                                <col [class.bg-warning]="s.id_inventory==default_stock.id_inventory" />
                            </ng-container>
                        
                        </colgroup>
                        <thead class="bg-light">
                            <ng-container *ngFor="let s of stocks">
                                <th [class.bg-warning]="s.id_inventory==default_stock.id_inventory" class="text-center">
                                    <h6 style="min-width:5rem;width:5vw;">{{s.inventoryname}}</h6>
                                    <h4 *ngIf="product.has_serials">{{s.stock}}</h4>
                                    <button *ngIf="product.has_serials && selectedSerial.length>0" class="btn btn-primary btn-xs" (click)="moveSerials(s.id_inventory)">Sposta qui &nbsp;<i class="fa fa-arrow-down"></i></button>
                                </th>
                            </ng-container>
                        </thead>
                        <tbody >
                            <tr>
                                <td *ngFor="let s of stocks" >
                                        <ng-container  *ngIf="product.has_serials">
                                            <select style="height: 50vh;overflow-y: scroll;" class="form-control p-0" multiple="true" name="selectedSerial" [(ngModel)]="selectedSerial" (ngModelChange)="selectSn(s,$event)">
                                                <ng-container *ngFor="let ser of s.serials" >
                                                    <option  [selected]="ser.selected" *ngIf="ser.avaible>0" [value]="ser.sn">
                                                        {{ser.sn}}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        
                                        
                                        </ng-container>
                                    
                                        <ng-container  *ngIf="!product.has_serials">
                                            <div >
                                                
                                                <button class="btn btn-success btn-block btn-default mt-1" (click)="deltaQuantity(s,1)"><i class="fa fa-plus"></i></button>
                                                <input min="0" step="1" class="form-control text-center text-xl" type="number" [(ngModel)]="s.stock" (change)="changeQuantity(s,$event.target.value)">
                                                <button class="btn btn-danger btn-block btn-default mb-1" (click)="deltaQuantity(s,-1)"><i class="fa fa-minus"></i></button>

                                            </div>
                                        </ng-container>
                                        
                                        
                                    
                                </td>
                            </tr>
                        </tbody>
                        
                    </table>

                    <div *ngIf="not_assigned>0" class="alert alert-danger mt-2">Quantità non assegnate {{not_assigned}}</div>
                    <div *ngIf="total>total_original" class="alert alert-warning mt-2">Quantità impostate non disponibili. Sarà effettuato una procedura di carico</div>
            
                </div>

                

            </div>
            <div class="alert alert-info alert-sm mt-2">
                Totale quantità del prodotto:&nbsp;<strong>{{product.stock}}</strong>
            </div>
        </div>
        
        <div class="modal-footer text-right">
            <button class="btn btn-success" *ngIf="createDDT" (click)="confirmDDT()" >Crea DDT di spostamento</button>
            <button class="btn btn-success" *ngIf="!createDDT" (click)="confirm()" >Conferma spostamenti</button>
            <button class="btn btn-secondary ml-2" (click)="cancel()">Annulla</button>
        </div>
    
</ng-container>