<section class="content">
    <div class="row my-1">
        <div class="col-lg-2 pr-md-0" >
            <app-filterdate [date_type]="100" [class]="'card treeviewlist p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
        </div>
        <div class="col-lg-10">
            <div class="card">
                <div class="card-header">
                    <b>Uscita</b>
                </div>
                <div class="card-body py-0">
                    <table class="table mb-0 no-border tabel-sm table-striped">
                        <thead>
                            <th valign="middle">Sezionale</th>
                            <th>
                                <table class="table mb-0">
                                    <th class="text-right">Totale</th>
                                    <th class="text-right">Pagato</th>
                                    <th class="text-right">Differenza</th>
                                </table>
                            </th>
                            <th></th>
                        </thead>
                        <tbody>
                            
                            <tr *ngFor="let r of list['out']">
                                <td>{{r.referenceprefix}}</td>
                                <td>
                                    <table class="table table-sm mb-0">
                                        <thead *ngIf="r.show">
                                            <th *ngIf="r.show">Tipo documento</th>
                                            <th class="text-right">Totale</th>
                                            <th class="text-right">Pagato</th>
                                            <th class="text-right">Differenza</th>
                                        </thead>
                                       
                                        <tbody *ngIf="r.show">
                                        <tr *ngFor="let d of r.documents" >
                                            <td *ngIf="r.show">
                                                <div *ngIf="d.typedocument==1" data-toggle="tooltip" title="Fattura Semplice" class="badge badge-info bg-purple">FS</div>
                                                <div *ngIf="d.typedocument==2" data-toggle="tooltip" title="Fattura Accompagnatoria" class="badge badge-info bg-teal" >FA</div>
                                                <div *ngIf="d.typedocument==12" data-toggle="tooltip" title="Fattura Acconto" class="badge badge-info bg-teal" >FAc</div>
                                                <div *ngIf="d.typedocument==3" data-toggle="tooltip" title="Nota di Credito" class="badge badge-info bg-pink">NC</div>
                                                <div *ngIf="d.typedocument==4" data-toggle="tooltip" title="Preventivo" class="badge badge-warning">PR</div>
                                                <div *ngIf="d.typedocument==5" data-toggle="tooltip" title="Documento di trasporto" class="badge badge-secondary">DDT</div>
                                                <div *ngIf="d.typedocument==10" data-toggle="tooltip" title="Trasferimento" class="badge badge-secondary">TR</div>
                                                <div *ngIf="d.typedocument==6" data-toggle="tooltip" title="Scontrino" class="badge badge-secondary">SC</div>
                                                <div *ngIf="d.typedocument==7" data-toggle="tooltip" title="Ordine cliente" class="badge badge-secondary">OC</div>
                                                <div *ngIf="d.typedocument==8" data-toggle="tooltip" title="Ordine fornitore" class="badge badge-secondary">OF</div>
                                                <div *ngIf="d.typedocument==9" data-toggle="tooltip" title="Non fiscale" class="badge badge-secondary">NF</div>
                                                <div *ngIf="d.typedocument==11" data-toggle="tooltip" title="Documento amministrativo" class="badge bg-olive">DA</div>
                                            </td>
                                            <td class="text-right">
                                                <ng-container *ngIf="d.accounted">
                                                    {{d.total | currency:" &euro;" }}
                                                </ng-container>
                                                <ng-container *ngIf="!d.accounted">
                                                   ( {{d.total | currency:" &euro;" }} )
                                                </ng-container>
                                            </td>
                                            <td class="text-right">
                                                <ng-container *ngIf="d.accounted">
                                                    {{d.paid | currency:" &euro;" }}
                                                </ng-container>
                                            </td>
                                            <td class="text-right" [class.text-danger]="d.paid-d.total<0" [class.text-success]="d.paid-d.total>=0">
                                                <ng-container *ngIf="d.accounted">
                                                    {{d.paid-d.total | currency:" &euro;" }}
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <td *ngIf="r.show"></td>
                                        <td class="text-right"><strong>{{r.total | currency:" &euro;"}}</strong></td>
                                        <td class="text-right"><strong>{{r.paid | currency:" &euro;"}}</strong></td>
                                        <td class="text-right" [class.text-danger]="r.paid-r.total<0" [class.text-success]="r.paid-r.total>=0"><strong>{{r.paid-r.total | currency:" &euro;"}}</strong></td>
                                    </tfoot>
                                    </table>
                                </td>
                               <td class="text-right">
                                   <button class="btn btn-xs btn-secondary" *ngIf="!r.show" (click)="r.show=true">Dettagli<i class="fas fa-chevron-down ml-1"></i></button>
                                   <button class="btn btn-xs btn-secondary" *ngIf="r.show" (click)="r.show=false">Nascondi<i class="fas fa-chevron-up ml-1"></i></button>
                               </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <b>Ingresso</b>
                </div>
                <div class="card-body">
                    <table class="table tabel-sm mb-0 no-border table-striped ">
                        <thead>
                            <th>Documento</th>
                            <th class="text-right">Totale</th>
                            <th class="text-right">Pagato</th>
                            <th class="text-right">Differenza</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of list['in']">
                                <td>
                                    <div *ngIf="r.typedocument==1" data-toggle="tooltip" title="Fattura Semplice" class="badge badge-info bg-purple">FS</div>
                                    <div *ngIf="r.typedocument==2" data-toggle="tooltip" title="Fattura Accompagnatoria" class="badge badge-info bg-teal" >FA</div>
                                    <div *ngIf="r.typedocument==12" data-toggle="tooltip" title="Fattura Acconto" class="badge badge-info bg-teal" >FAc</div>
                                    <div *ngIf="r.typedocument==3" data-toggle="tooltip" title="Nota di Credito" class="badge badge-info bg-pink">NC</div>
                                    <div *ngIf="r.typedocument==4" data-toggle="tooltip" title="Preventivo" class="badge badge-warning">PR</div>
                                    <div *ngIf="r.typedocument==5" data-toggle="tooltip" title="Documento di trasporto" class="badge badge-secondary">DDT</div>
                                    <div *ngIf="r.typedocument==10" data-toggle="tooltip" title="Trasferimento" class="badge badge-secondary">TR</div>
                                    <div *ngIf="r.typedocument==6" data-toggle="tooltip" title="Scontrino" class="badge badge-secondary">SC</div>
                                    <div *ngIf="r.typedocument==7" data-toggle="tooltip" title="Ordine cliente" class="badge badge-secondary">OC</div>
                                    <div *ngIf="r.typedocument==8" data-toggle="tooltip" title="Ordine fornitore" class="badge badge-secondary">OF</div>
                                    <div *ngIf="r.typedocument==9" data-toggle="tooltip" title="Non fiscale" class="badge badge-secondary">NF</div>
                                    <div *ngIf="r.typedocument==11" data-toggle="tooltip" title="Documento amministrativo" class="badge bg-olive">DA</div>
                                </td>
                                <td class="text-right"><strong>{{r.total | currency:" &euro;"}}</strong></td>
                                <td class="text-right"><strong>{{r.paid | currency:" &euro;"}}</strong></td>
                                <td class="text-right" [class.text-danger]="r.paid-r.total<0" [class.text-success]="r.paid-r.total>=0"><strong>{{r.paid-r.total | currency:" &euro;"}}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>