
<ng-container *ngIf="field.type=='separator'">
    <div class="form-group">
        <h5 *ngIf="field.label" class="text-info">{{field.label}}</h5>
        <hr/>
    </div>
</ng-container>

<div class="form-group">
    <i *ngIf="field.value!=''" class="text-success fa fa-check mr-2"></i><label>{{field.label}} <span *ngIf="field.required" class="text-danger">&nbsp;*</span></label>
    
    
    <ng-container *ngIf="field.description">
        <br/>
        <i  data-toggle="tooltip" title="{{field.description}}" class="fas fa-info-circle"><span [innerHTML]="field.description"></span></i>
    
    </ng-container>
    
    
    <ng-container *ngIf="field.type=='text'">
        <input type="text" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='number'">
        <input type="number" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='textarea'">
        <textarea type="text" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value"></textarea>
    </ng-container>

    <ng-container *ngIf="field.type=='date'">
        <input type="date" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='time'">
        <input type="time" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='boolean'">
        <select [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
            <option value="0">No</option>
            <option value="1">Si</option>
        </select>
    </ng-container>

    <ng-container *ngIf="field.type=='checkbox'">
        <input type="checkbox" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='select'">
        <select [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
            <option value="{{o}}" *ngFor="let o of field.params.split(';')">{{o}}</option>
        </select>
    </ng-container>

    <ng-container *ngIf="field.type=='file'">
        <div class="input-group input-group-sm">
            <input type="text" [readonly]="true" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
            <div class="input-group-append">
                <button class="btn btn-secondary" *ngIf="!disabled" (click)="uploadFile()">Sfoglia</button>
                <button class="btn btn-info" *ngIf="field.value" (click)="downloadFile(field.value)"><i class="far fa-eye"></i></button>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="field.type=='photo'">
        <br/>
        <ng-container *ngIf="isPhotoMultiple(field)">
            <div class="row">
                <ng-container *ngFor="let i of parseMultiplePhoto(field.value)">
                    <div class="col-3">
                        <img class="img-thumbnail" style="max-width: 100px" src="{{pathImage(i)}}">
                        <button *ngIf="!disabled" style="position: absolute;left: 5px;top: -7px;"class="btn btn-danger btn-sm" (click)="deletePhoto(field,i)"><i class="fa fa-times"></i></button>
                    </div>
                </ng-container>
            </div>
            <button *ngIf="!disabled" class="mt-2 btn btn-block btn-success" (click)="addPhoto(field)"><i class="fa fa-camera"></i>&nbsp;Aggiungi foto</button>
        </ng-container>

        <ng-container *ngIf="!isPhotoMultiple(field)">
            <div class="row">
                <div class="col-3">
                    <img *ngIf="field.value!=''" class="img-thumbnail" style="max-width: 100px" src="{{pathImage(field.value)}}" >
                    <button *ngIf="field.value!='' && !disabled" style="position: absolute;left: 5px;top: -7px;"class="btn btn-danger btn-sm" (click)="field.value=''"><i class="fa fa-times"></i></button>
           
                </div>
            </div>
            <button *ngIf="field.value!='' && !disabled" class="mt-2 btn btn-block btn-info" (click)="takePhoto(field);"><i class="fa fa-camera"></i>&nbsp;Cambia foto</button>
            <button *ngIf="field.value=='' && !disabled" class="mt-2 btn btn-block btn-success" (click)="takePhoto(field);"><i class="fa fa-camera"></i>&nbsp;Scatta 1 foto</button>
            
        </ng-container>
       
    </ng-container>
</div>




