import { Globals } from "projects/core/src/globals";
import { CustomField } from "projects/core/src/include/structures";
import {Contact} from "../db/contact";
import {Note} from './note';


export class Address {
    id: number;
    name: string;
    id_type: number;
    address: string;
    city: string;
    zip: string;
    nation:string;
    country: string;
    vat_number: string;
    tax_code: string;
    sdi:string;
    pec:string;
    role: string;
    contacts:Contact[];
    status:number;
    customfields:CustomField[];
    balance:number;
    notes:Note[];
    id_user:number;
    files:File[];
    id_financialaccount_default:number;
    username:string;

    constructor(){
        this.id=0;
        this.nation="IT";
        this.sdi="0000000";
        this.zip="";
    }


    findUsername(){
       let id_type_contact_username=Globals.parameters.get("address").getParam("id_type_contact_username",0);
       if(id_type_contact_username>0){
           for(let c of this.contacts){
               if(c.id_type==id_type_contact_username){
                   this.username=c.value;
               }
           }
       }

    }

}
