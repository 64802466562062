import { Injectable } from '@angular/core';
import { WSService } from 'projects/core/src/include/service';
import { DocumentTemplate } from '../db/documenttemplate';

@Injectable({
  providedIn: 'root'
})
export class DocumentsTemplateService extends WSService<DocumentTemplate> {

  constructor() {super("documentstemplate") }
}
