import { Injectable } from '@angular/core';
import {WSService} from 'projects/core/src/include/service'
import { Contact } from '../db/contact';
@Injectable({
  providedIn: 'root'
})
export class ContactsService extends WSService<Contact> {

  constructor() {super("contacts") }



  
}

