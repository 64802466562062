import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { DocumentTemplate } from '../../../db/documenttemplate';
import { DocumentsTemplateService } from '../../../services/documents-template.service';
import { DocumentsTemplateDetailComponent } from './documents-template-detail/documents-template-detail.component';

@Component({
  selector: 'app-documents-template',
  templateUrl: './documents-template.component.html',
  styleUrls: ['./documents-template.component.css']
})
export class DocumentsTemplateComponent extends ModelList<DocumentTemplate> implements OnInit {

  constructor( private fb1: FormBuilder,
    private documentstemplateService: DocumentsTemplateService,
  ){
    super(documentstemplateService,fb1);
    this.detailViewModal=DocumentsTemplateDetailComponent;
  }

  filter_search:Filter=new Filter();

  ngOnInit(): void {


    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("dt.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.getItems();
  }

}
