import { Component, OnInit, Input, TemplateRef,ViewChild } from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ActivatedRoute } from '@angular/router';
import { Contracts } from '../../../db/contracts';
import { Type } from '../../../db/type';
import { TypesService } from '../../../services/types.service';
import { ShopsService } from '../../../services/shops.service';
import { ContractsService } from '../../../services/contracts.service';
import { InstallationsService } from '../../../services/installations.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ShopsComponent } from '../../shops/shops.component';
import { SerialsComponent } from '../../products/serials/serials.component';
import { DeadlinesComponent } from '../../deadlines/deadlines.component';
import { SerialListComponent } from '../../products/serials/list/list.component';



export interface generateInstallment{
  start:Date;
  end:Date;
  amount:number;
  tax:number;
  gross:number;
  amount_type:number;
  type:number;
  typedetail:number;
  params:any;
}



@Component({
  selector: 'app-contracts-detail',
  templateUrl: './contracts-detail.component.html',
  styleUrls: ['./contracts-detail.component.sass']
})

export class ContractsDetailComponent extends Model<Contracts> implements OnInit {
  
  @ViewChild(DeadlinesComponent)
  installments:DeadlinesComponent;
  
  @Input()
  id_shop:number;

  @Input()
  id_installation:number;

  type_contract:Type[];

  tab=0;

  generate:generateInstallment;


  @ViewChild('generateInstallmentsModal')
  generateInstallmentsModal:TemplateRef<any>;
  generateInstallmentsModalRef;
  
  field_required=['start','end','id_shop','amount'];
  constructor(
    private route1: ActivatedRoute,
    private contractsService: ContractsService,
    private typesService: TypesService,
    private shopsService: ShopsService,
    private installationsService: InstallationsService,
    private modalService: NgbModal
    
    
    ) {
      super(route1,contractsService);
      
    }

    ngOnInit(): void {
      this.title = 'Contratto Dettaglio';
      if(this.mode!="modal"){
        this.route1.params.subscribe((params) =>{
          this.id = params.id
          if(params.id_shop)
            this.id_shop=params.id_shop;
          if(params.id_installation)
            this.id_installation=params.id_installation;
        } );
      }
  
      this.typesService.getTypes("contracts").subscribe((items)=>{
        this.type_contract=items;
        this.getItem();
       });
  
  
     this.afterGetItem=(()=>{
       
      if(this.id==0){
        this.record.tax=22;
        if(this.id_shop>0){
          //cerca l'anagrafica corretta
          this.shopsService.getItem(this.id_shop).subscribe((item)=>{
          this.record.shop=item;
          this.record.id_shop=item.id;
          
          
          
          });
        }


        if(this.id_installation>0){
          this.record.installations=[];
          this.installationsService.getItem(this.id_installation).subscribe((item)=>{
            this.record.installations=[];
            this.record.installations.push(item);
          });
          

        }
        this.record.phase=1;
        this.record.amount_type=30;
        this.record.customfields=[];
        this.record.id_type=this.type_contract[0].id;
      }
      this.calculateGross();
    });
  
    }

    addInstallation(){
      
      Globals.modal.showModal(SerialListComponent,[{"name":"mode","value":"modal"}],(instance)=>{
        let r=instance["recordSelected"];
        if(this.record['installations']==undefined)
          this.record['installations']=[];
        this.record['installations'].push(r);
      });
      
    }

    calculateTotal(){
      this.record.amount=0;
      for(let i=0;i<this.record['installations'].length;i++){
        let price=parseFloat(this.record['installations'][i]['price'].toString().trim())/(1+this.record.tax/100);
        //this.record['installations'][i]['price']=price;
        this.record.amount=this.record.amount+price;
        

      }
      this.calculateGross();
    }

    deleteInstallation(p){
      for(let i=0;i<this.record['installations'].length;i++){
        if(this.record['installations'][i]==p){
          this.record['installations'].splice(i,1);
          return;
        }

      }
      
    }


    calculateGross(){
      this.record.gross=parseFloat((this.record.amount*(1+this.record.tax/100)).toFixed(2));

    }

    calculateNet(){
      this.record.amount=parseFloat((this.record.gross/(1+this.record.tax/100)).toFixed(2));

    }

    openGenerateInstallments(){
      this.generate={} as generateInstallment;
      this.generate.start=this.record.start;
      this.generate.end=this.record.end;
      this.generate.amount=this.record.amount;
      this.generate.tax=this.record.tax;
      this.generate.gross=this.generate.amount*(1+this.generate.tax/100);
      this.generate.amount_type=30;
      this.generate.type=1;
      this.generate.typedetail=30;
      
      this.generateInstallmentsModalRef=this.modalService.open(this.generateInstallmentsModal);
    }

    generateInstallments(){
        
      if(this.generate.typedetail==-1){
        if(this.generate.params=="" || this.generate.params==undefined || this.generate.params<-1 || this.generate.params>31){
          alert("Specificare un giorno del mese valido per la generazione delle rate");
          return;
        }
      }

        Globals.setLoading(true);
        this.contractsService.generateInstallments(
          Helper.convertDateControl(
            Helper.convertString2Date(this.generate.start.toString())
          ),
          Helper.convertDateControl(
            Helper.convertString2Date(this.generate.end.toString())
          ),
          this.generate.amount,
          this.generate.tax,
          this.generate.amount_type,
          this.generate.type,
          this.generate.typedetail,
          this.record.id,
          this.record.shop.id_address,
          this.generate.params
       ).subscribe((items)=>{
          //this.record.installments=items;
          this.closeGenerateInstallments();
          Globals.setLoading(false);
          this.installments.updateList();
          //TODO da controllare this.deadlinesView.updateList();

        });


    }

    selectShop(){
      Globals.modal.showModal(ShopsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
        this.record.shop=instance['recordSelected'];
        this.record.id_shop=this.record.shop.id;
        
      })
    }


    closeGenerateInstallments(){
      this.generateInstallmentsModalRef.close("success");
    }

    changeType(){
      if(this.generate.type==0){
        this.generate.typedetail=7;
      }else{
        this.generate.typedetail=30;
     
      }

    }

    calculateGrossGenerate(){
      this.generate.gross=this.generate.amount*(1+this.generate.tax/100);
    }

    calculateNetGenerate(){
      this.generate.amount=this.generate.gross/(1+this.generate.tax/100);
    }


    save(reloadItem:boolean=true,oncomplete:Function=null){

        //se il contratto viene passato allo stato concluso o annullato (annulla le voci di agenda in futuro)
      if(this.record.phase>1){
        if(this.installments.list.length>0){
          if(confirm("Ci sono delle voci nel piano rate. Impostarle come archiviate?")){
            this.contractsService.setArchivedInstallments(this.record.id).subscribe(()=>{

            });
          }
        }
      }


      super.save.apply(this,[reloadItem,oncomplete]);
    }
}
