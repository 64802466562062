import { Component, ViewChild, OnInit,Input, Output,EventEmitter } from '@angular/core';
import { Helper } from '../../helper';
import {UploadService} from '../../lib/upload.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.sass']
})
export class UploadFileComponent implements OnInit {

  @ViewChild('modal', { static: false })
  modal: ModalComponent ;

  @Input()
  subdirectory:string;

  @Input()
  description:string;

  @Input()
  extensions:string="*";

  fileToUpload: File = null;
  
  filename:string;
  default_filename:string="";

  @Input()
  params:any;

  @Input()
  record:any; //necessario nel caso di parserizzazione delle keyword dei parametri

  @Output()
  onUploaded:EventEmitter<string>=new EventEmitter<string>()
  
  constructor(
    private uploadService:UploadService
  ) { }

  ngOnInit(): void {

    

  }


  ngOnChanges(){
   
      this.parseParams();
   
    
  }

  parseParams(){

    


    if(this.params){
      if(!Array.isArray(this.params)){
        try{
          this.params=JSON.parse(this.params);
        }catch{
          this.params=[];
        }
      }

      if(this.params.length>0){
        for(let p of this.params){
          if(p.name=="subdirectory"){
            this.subdirectory=this.parseKeyword(p.value,this.record);
          }

          if(p.name=="default_filename"){
            this.default_filename=this.parseKeyword(p.value,this.record);
          }
        }
      }

      
      

    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.OnSubmit();
 }


  OnSubmit() {
    this.uploadService.uploadFile(this.fileToUpload,this.subdirectory,(result)=>{
      if(this['modalWindow'])
        this['modalWindow'].close("success");
      this.filename=result;
      this.onUploaded.emit(result);
     
    },false,this.default_filename);
  }


  parseKeyword(value,record){
    
    if(record){
      
      return Helper.replaceKeyword(value,record);
    }
      
    else
      return value;
  }


  close(){
    this['modalWindow'].close("cancel");
  }
}
