<app-window [title]="'Dettaglio progetto'">
    <form role="form" id="detailForm">
        
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item ">
                <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#tab1Pro" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab4Pro" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fas fa-file-alt"></i><span class="d-none d-sm-inline-block ml-2">Documenti</span></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab5Pro" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-dollar"></i><span class="d-none d-sm-inline-block ml-2">Costi</span></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab6Pro" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-hdd"></i><span class="d-none d-sm-inline-block ml-2"></span></a>
            </li>
        </ul>
    
        <div class="p-2" >
            <div class="tab-content" id="myTabContent">
                <!-- GENERALE -->
                <div class="tab-pane fade show active" id="tab1Pro" role="tabpanel" aria-labelledby="tab2-tab">
                    <div class="form-group row">
                        <div class="col-2"><label>Data</label></div>
                        <div class="col-4">
                            <input type="date" class="form-control form-control-sm" name="date" [(ngModel)]="record.date">
                        </div>
                        <div class="col-2 text-right"><label>Fase</label></div>
                        <div class="col-4">
                            <select class="form-control form-control-sm" name="phase" [(ngModel)]="record.phase">
                                <option value="1">preventivato</option>
                                <option value="2">in corso</option>
                                <option value="3">concluso</option>
                                <option value="4">annullato</option>
                                
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-2"><label>Anagrafica</label></div>
                            <div class="col-12 col-lg-10">
                                <div class="small-box alert alert-info mb-0">
                                    <app-selectfieldcustom [showViewButton]="true" [showSearchButton]="true"  (OnExplore)="selectAddress()" [recordSelected]="record.addressItem" [service]="addressesService"  [fieldRender]="'name'" [class]="'form-control form-control-sm'" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"></app-selectfieldcustom>
                                    <hr class="mt-0">
                                    <div class="mt-w" *ngIf="record.addressItem && record.addressItem.id>0">
                                        <small>
                                            <div class="row">
                                                <div class="col-6">
                                                    <!-- <label>Dati generali</label><br/> -->
                                                    {{record.addressItem.address}}<br>
                                                    {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                                    <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span><br>
                                                    <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}</span>
                                                </div>
                                                <div class="col-6">
                                                    <!-- <label>Contatti</label> -->
                                                    <ul class="list-group">
                                                        <li  *ngFor="let c of record.addressItem.contacts">{{c.value}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </small>
                                        <div class="icon"><i class="fa fa-user"></i></div>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-2"><label>Titolo</label></div>
                        <div class="col-10">
                            <input type="text"  class="form-control form-control-sm" name="name" [(ngModel)]="record.name">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-2"><label>Budget</label></div>
                        <div class="col-10">
                            <input type="text"  class="form-control form-control-sm" name="amount" [(ngModel)]="record.amount">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-2"><label>Descrizione</label></div>
                        <div class="col-10">
                            <textarea  class="form-control form-control-sm" name="description" [(ngModel)]="record.description"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-2"><label>Note</label></div>
                        <div class="col-10">
                            <textarea  class="form-control form-control-sm" name="note" [(ngModel)]="record.note"></textarea>
                        </div>
                    </div>
                </div>
                <!-- DOCUMENTI -->
                <div class="tab-pane fade" id="tab4Pro" role="tabpanel" aria-labelledby="tab2-tab">
                        <app-documentlist #documentlist [filterbox]="false" [type]="1" [mode]="'embedded'" [id_table]="record.id" [table]="'project'"></app-documentlist>
                        <button type="button" class="btn btn-sm btn-primary" (click)="addDocument(1);"><i class="fa fa-plus mr-2"></i>Aggiungi</button>
                        <button type="button" class="btn btn-sm btn-primary ml-2" (click)="linkDocument(1);"><i class="fa fa-plus mr-2"></i>Collega documento</button>
                </div>
                <!-- COSTI -->
                <div class="tab-pane fade" id="tab5Pro" role="tabpanel" aria-labelledby="tab2-tab">
                    <app-documentlist #costlist [filterbox]="false" [type]="2" [mode]="'embedded'" [id_table]="record.id" [table]="'project'"></app-documentlist>
                    <button type="button" class="btn btn-sm btn-primary" (click)="addDocument(2);"><i class="fa fa-plus mr-2"></i>Aggiungi</button>
                    <button type="button" class="btn btn-sm btn-primary ml-2" (click)="linkDocument(2);"><i class="fa fa-plus mr-2"></i>Collega documento</button>
                </div>
                <!-- FILE -->
                <div class="tab-pane fade" id="tab6Pro" role="tabpanel" aria-labelledby="tab1-tab">                                
                    <app-files [files]="record.files" [directory]="'projects/'+id"></app-files>
                </div>
            </div>
        </div>
    </form> 
</app-window>
<app-toolbarmodel [model]="this"></app-toolbarmodel>
 