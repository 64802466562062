import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { File, ToastMode } from 'projects/core/src/include/structures';
import { FilesService } from 'projects/core/src/lib/files.service';

@Component({
  selector: 'app-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.css']
})
export class UploadfileComponent implements OnInit {
  fileToUpload = null;

  @Input()
  directory:string="";

  @Output()
  fileuploaded:EventEmitter<any>=new EventEmitter();

  @Input()
  hashfile=false;

  @Input()
  saveAfterUpload=false;

  constructor(
    private uploadService:UploadService,
    private filesService:FilesService

  ) { }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,this.directory,(result)=>{
      if(result){
        let df:File=new File();
        df.file=result;
        df.name=this.fileToUpload['name'];
        df.table=this.directory;

       

        if(this.saveAfterUpload){
          this.filesService.save(df,()=>{
            this.fileuploaded.emit(df);
          });
        }else{
          this.fileuploaded.emit(df);
        }
        

      }else{
        Globals.message.showToaster("Errore nel caricamento del file",ToastMode.DANGER);
      }
      Globals.setLoading(false);
     
    },this.hashfile);
}

}
