import { Component, Input, OnInit } from '@angular/core';
import {Modal} from 'projects/core/src/include/modal';

import {Contact} from '../../db/contact';

import {Notification, NotificationMessages} from '../../db/notification';

import { NotificationService } from '../../services/notification.service';
@Component({
  selector: 'app-previewnotification',
  templateUrl: './previewnotification.component.html',
  styleUrls: ['./previewnotification.component.sass']
})
export class PreviewnotificationComponent extends Modal implements OnInit {


  @Input()
  id_notification=0;

  step=0;

  constructor( private notificationService: NotificationService,) {
    super();
   }

   notifications:Notification[];
   selectedNotify:Notification;
   gateway_notifications=[];
   gateway_email:NotificationMessages;
  gateway_sms:NotificationMessages;
  gateway_whatsapp:NotificationMessages;


  contacts:Contact[];
  record:any;
   table:string;
   tab=0;
  ngOnInit(): void {

    
    this.notificationService.getNotifications(this.table,this.record.id).subscribe((items)=>{
      
      this.notifications=[];
      if(items.length>0){
        //verifica le condizioni (se presenti)
        for(let i=0;i<items.length;i++){
          try{
            items[i].conditionsArray=JSON.parse(items[i].conditions);
            if(items[i].conditionsArray.length>0){

              let allConditionsVerified=true;

              for(let j=0;j<items[i].conditionsArray.length;j++){
                if(this.record[items[i].conditionsArray[j].field]!=items[i].conditionsArray[j].value && allConditionsVerified){
                  allConditionsVerified=false;

                  
                }


              }

              if(allConditionsVerified)
                this.notifications.push(items[i]);

            }else{
              this.notifications.push(items[i]);
            }
          }catch{
            this.notifications=items;
          }

        }
        

        if(this.notifications.length==1){
          this.selectedNotify=this.notifications[0];
          this.getNotification();
        }
          

        //se è stato specificato una precisa notifica
        if(this.id_notification>0){
          for(let n of this.notifications){
            if(n.id==this.id_notification)
              this.selectedNotify=n;
          }
        }


        
      }
    });
/*
    this.gateway_email={} as NotificationMessages;
    this.gateway_email.gateway="email";
    this.setContact(this.gateway_email);

    this.gateway_sms={} as NotificationMessages;
    this.gateway_sms.gateway="sms";
    this.setContact(this.gateway_sms);

    this.gateway_whatsapp={} as NotificationMessages;
    this.gateway_whatsapp.gateway="whatsapp";
    this.setContact(this.gateway_whatsapp);
*/
    

  }

  setContact(gateway:NotificationMessages){
    gateway['contacts']=[]
    if(this.contacts && this.contacts.length>0){
      for(let i=0;i<this.contacts.length;i++){
        if(this.contacts[i].id_type==gateway.id_type){
          gateway['contacts'].push(this.contacts[i])
          gateway['enable']=1;
          
          //if(gateway['contact']==undefined)
            gateway['contact']=this.contacts[i].value;
        }
      }
    }
  }

  getNotification(){
    this.step=1;
    this.gateway_email={} as NotificationMessages;
    this.gateway_notifications=[];
    for(let i=0;i<this.selectedNotify.gateways.length;i++){
      if(this.selectedNotify.gateways[i].id_type>0){


        let g=new NotificationMessages();
        g=this.selectedNotify.gateways[i];
        g.gateway=this.selectedNotify.gateways[i].gateway;
        this.setContact(g);
        this.gateway_notifications.push(g);

        /*
        switch(this.selectedNotify.gateways[i].gateway){
          case "email":
            this.gateway_email=this.selectedNotify.gateways[i];
            this.gateway_email.gateway="email";
            this.setContact(this.gateway_email);
            break;
          case "sms":
            this.gateway_sms=this.selectedNotify.gateways[i];
            this.gateway_sms.gateway="sms";
            this.setContact(this.gateway_sms);
            break;
          case "whatsapp":
            this.gateway_whatsapp=this.selectedNotify.gateways[i];
            this.gateway_whatsapp.gateway="whatsapp";
            this.setContact(this.gateway_whatsapp);
            break;
      
        }
        */
      }
    }
  }


  close(response=""){
    this['modalWindow'].close(response);
  }

}
