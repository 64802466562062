import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [];
routes.push({ path: 'login', loadChildren: () => import('projects/core/src/common/login/login.module').then(m => m['LoginModule']) });
routes.push({ path: 'booking', loadChildren: () => import('./views/bookings/bookings.module').then(m => m.BookingsModule) });
routes.push({ path: 'booking/rentalprices', loadChildren: () => import('./views/bookings/bookings.module').then(m => m.BookingsModule) });
routes.push({ path: 'inventories', loadChildren: () => import('./views/inventories/inventories.module').then(m => m.InventoriesModule) });

routes.push({ path: 'inventories/history', loadChildren: () => import('./views/inventories/inventories.module').then(m => m.InventoriesModule) });


//!!!!!! NECESSARIO PER GARANTIRE LE CORRETTE DIPENDENZE !!!!!!
routes.push({ path: 'admin-booking', loadChildren: () => import('projects/c1-backend/src/app/views/booking/booking.module').then(m => m.BookingModule) });
routes.push({ path: 'admin-address', loadChildren: () => import('projects/c1-backend/src/app/views/address/address.module').then(m => m.AddressModule) });
routes.push({ path: 'admin-project', loadChildren: () => import('projects/c1-backend/src/app/views/projects/projects.module').then(m => m.ProjectsModule) });
routes.push({ path: 'admin-ads', loadChildren: () => import('projects/modules/src/app/ads/ads.module').then(m => m.AdsModule) });


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
