<div class="modal-body" *ngIf="type==1">
    <h5>Inserisci i numeri seriali dei prodotti da caricare</h5>
    <p><small><i>Intervallati dal segno ; o dal ritorno a capo</i></small></p>
    <div class="form-group">
        <textarea #serialsToLoadBox  autofocus class="form-control" rows="10" cols="20" [(ngModel)]="serialsToLoad"></textarea>
       
        <i class="mt-1">Numeri seriali inseriti: <strong>{{countLoaded}}</strong></i>
        <!--<div class="alert alert-danger" *ngIf="countLoaded>quantity">Inseriti numeri seriali in eccesso !</div>-->
    </div>
    <div class="row ">
        <div class="col-4">Pattern per autogenerazione SN</div>
        <div class="col-5">
            <div class="input-group input-group-sm">
                <input type="text" class="form-control form-control-sm" name="snpattern" placeholder="ex. AA{sn}" [(ngModel)]="snpattern" />
                <div class="input-group-append">
                    <input type="number" class="form-control form-control-sm" name="quantity" [(ngModel)]="quantity">
                    <button class="btn btn-primary" *ngIf="type==1" [disabled]="snpattern=='' || snpattern==undefined" (click)="generate()">Genera</button>
                </div>
            </div>
        </div>
    </div>

    <button class="btn btn-sm btn-primary ml-2 mt-2" (click)="scan()">Scansiona</button>
</div>

<div class="modal-body" *ngIf="type==2">
    <h5>Seleziona i numeri seriali dei prodotti da scaricare</h5>
    <table class="table table-head-fixed table-sm">
        <thead class="bg-secondary">
            <th></th>
            <th>Seriale</th>
            <th>Disponibilità</th>
            <th>Magazzino</th>
        </thead>
        <tbody>
            <ng-container *ngFor="let s of serials">
            <tr *ngIf="s.avaible>0">
                <td><input type="checkbox" [(ngModel)]="s.selected" /></td>
                <td>{{s.sn}}</td>
                <td>{{s.avaible}}</td>
                <td>{{s.inventoryname}}</td>
            </tr>
            </ng-container>
        </tbody>
    </table>

    <i class="mt-1">Numeri seriali selezionati: <strong>{{countSelected}}</strong> su <strong>{{quantity}}</strong></i>
    <div class="alert alert-danger" *ngIf="countSelected>quantity">Selezionati numeri seriali in eccesso !</div>

</div>


<div class="modal-footer">
   
    <button *ngIf="serials.length==0" class="btn btn-success" (click)="confirm(true)">Continua</button>
    <button *ngIf="serials.length>0" class="btn btn-success" (click)="confirm()">Ok (F8)</button>
    <button class="btn btn-secondary" (click)="close()">Annulla</button>
    

</div>