import { CustomField } from "projects/core/src/include/structures";


export class Activities {
    id:number;
    date:Date;
    time:string;
    id_type: number;
    type:any;
    id_user:number;
    id_table:number;
    table:string;
    subject:string;
    description:string;
    status:number;
    phase:number;
    priority:number;
    note:string;
    customfields:CustomField[];
    username:string;
    reference:any;
    id_deadline:number;
    amount:number;
    tax:number;
    gross:number;
    paid:number;
    paid_description:string;
    paid_type:number;
    latitude:number;
    longitude:number;
    id_cashflow:number;
    missingValues:any[];
    id_address:number;
    name:string;
    balance:number;
    method_payment:string;
    reschedule:Date;
    note_reschedule:string;
    enable_accounting:boolean;
    id_form:number;
}
