import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { ProductCategories } from '../db/productcategories'
import { WSService } from 'projects/core/src/include/service'
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})

export class ProductcategoriesService extends WSService<ProductCategories> {

  constructor() { super("productcategories") }

  getCategories():Observable<ProductCategories[]>{
    return  Globals.ws.requestUrl<ProductCategories[]>("productcategories","getItems",["pc.status=1"]);
    
  }

  getTree():Observable<TreeviewItem[]>{
    return  Globals.ws.requestUrl<TreeviewItem[]>("productcategories","getTree",[]);
   
  }

}