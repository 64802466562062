<ng-container *ngIf="recordSelected==undefined">
  <div class="input-group">
    <input list="values" id="searchfield" type="text" autocomplete="name" class="{{class}}"  [(ngModel)]="searchText" />
    <div class="input-group-append">
      <a type="button" data-toggle="tooltip" title="Aggiungi" class="btn btn-sm btn-success" (click)="add()" *ngIf="showAddButton"><i class="fa fa-plus"></i></a>
      <button type="button" data-toggle="tooltip" title="Cerca" *ngIf="showSearchButton" class="btn btn-sm btn-primary" (click)="explore()"><i class="fa fa-search"></i></button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="recordSelected!=undefined">
  <div class="input-group">
    <h5 [class.trashed]="recordSelected['status']==2">{{recordSelected[fieldRender]}}</h5>
    <div class="input-group-append">
      <a type="button" data-toggle="tooltip" title="Seleziona" class="btn btn-sm text-secondary" (click)="edit()"><i class="fas fa-list"></i></a>
      <a type="button" data-toggle="tooltip" title="Visualizza / Modifica" *ngIf="showViewButton" class="btn btn-sm text-secondary" (click)="view()"><i class="fas fa-eye"></i></a>
      <a type="button" data-toggle="tooltip" title="Dissocia" *ngIf="showUnlinkButton" class="btn btn-sm text-secondary" (click)="unlink()"><i class="fas fa-unlink"></i></a>
 
    </div>
  </div>
  
</ng-container>


<datalist id="values">
    <ng-container *ngFor="let r of list">
      <option value="{{r[fieldRender]}}"></option>
    </ng-container>
    <option value="Aggiungi"></option>
</datalist>