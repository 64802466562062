<app-window [title]="title">
    <form role="form" id="detailForm">
        <div class="row mb-3">
            <div class="col-lg-2"><label>Data</label></div>
            <div class="col-lg-4">
                <div class="input-group" >
                    <input class="form-control form-control-sm" type="date"  name="date"  [(ngModel)]="record.date"  />
                    <input class="form-control form-control-sm" type="time"  name="time"  [(ngModel)]="record.time"  />
                </div>
            </div>
            <div class="col-lg-1"><label>Magazzino</label></div>
            <div class="col-lg-2">
                <select class="form-control form-control-sm" [(ngModel)]="record.id_inventory" name="id_inventory" #id_inventory="ngModel" required >
                    <option *ngFor="let i of inventories" [value]="i.id">{{i.name}}</option>
                </select>
            </div>
            <div class="col-lg-1"><label>Tipologia</label></div>
            <div class="col-lg-2">
                <select class="form-control form-control-sm" [(ngModel)]="record.type" name="type" #type="ngModel" [class.alert-success]="record.type==1" [class.alert-danger]="record.type==2">
                    <option value="1">Carico</option>
                    <option value="2">Scarico</option>
                </select>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-lg-2"><label>Prodotto</label></div>
            <div class="col-lg-6">

                <div class="input-group">
                    <ng-container *ngIf="record.product">
                        <input name="description" type="text" readonly class="form-control form-control-sm" value="{{record.product.brand}} {{record.product.name}}" />
                    </ng-container>
                    <ng-container *ngIf="!record.product">
                        <input name="description" type="text" readonly class="form-control form-control-sm" value="{{record.description}}" />
                    </ng-container>
                    <div class="input-group-append input-group-append-sm">
                        <button class="btn btn-sm btn-secondary" (click)="selectProduct()">Seleziona</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 text-lg-right"><label>SN</label></div>
            <div class="col-lg-3">
                <input name="sn" type="text" class="form-control form-control-sm" [(ngModel)]="record.sn" />
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-lg-2"><label>Quantità</label></div>
            <div class="col-lg-1">
                <div class="input-group input-group-sm" >
                    <input class="form-control form-control-sm" #quantity type="number" min="0" step="1"  name="quantity"  [(ngModel)]="record.quantity" (change)="setPrice();calculateGrossDetail()" (keyup)="setPrice();calculateGrossDetail()"  />
                </div>
            </div>
            <div class="col-lg-2 text-lg-right"><label>U.M.</label></div>
            <div class="col-lg-2">
                <div class="input-group input-group-sm" >
                    <input class="form-control  form-control-sm" type="text"  name="unit"  [(ngModel)]="record.unit" />
                </div>
            </div>
            <div class="col-lg-3 text-lg-right"><label>Prezzo unitario</label></div>
            <div class="col-lg-2">
                <combobox [class]="'form-control form-control-sm'" *ngIf="record.product && record.type==2 && record.product.pricelist!=undefined && record.product.pricelist.length>0" [dataList]="record.product.pricelist" [value]="record.netprice" (onEntrySelected)="record.netprice=$event;calculateGrossDetail();" [columnName]="'tag;price'" [columnValue]="'price'"></combobox>
                <div class="input-group input-group-sm" *ngIf="record.type==1">
                    <input  type="number" min="0" step="0.01" name="netprice" class="form-control form-control-sm pr-0" [(ngModel)]="record.netprice"  (change)="calculateGrossDetail()" (keyup)="calculateGrossDetail()"  />
                    <div class="input-group-prepend input-group-prepend-sm">
                        <span class="input-group-text input-group-text-sm px-1">&euro;</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-lg-10 text-lg-right"><label>Imponibile</label></div>
            <div class="col-lg-2">
                <div class="input-group input-group-sm">
                    <p  class="form-control form-control-sm" >{{record.netprice*record.quantity}}</p>
                    <div class="input-group-prepend input-group-prepend-sm">
                        <span class="input-group-text input-group-text-sm px-1">&euro;</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-lg-2"><label>Sconto</label></div>
            <div class="col-lg-2 pr-lg-0">
                <div class="input-group input-group-sm">
                    <input type="number" min="0" step="1" name="discount1" class="form-control  form-control-sm pr-0" [(ngModel)]="record.discount1" (change)="calculateGrossDetail()" (keyup)="calculateGrossDetail()"   />
                    <div class="input-group-prepend input-group-prepend-sm">
                        <span class="input-group-text input-group-text-sm px-1">%</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 px-lg-0">
                <div class="input-group input-group-sm">
                    <input type="number" min="0" step="1" name="discount2" class="form-control  form-control-sm pr-0" [(ngModel)]="record.discount2" (change)="calculateGrossDetail()" (keyup)="calculateGrossDetail()"   />
                    <div class="input-group-prepend input-group-prepend-sm">
                        <span class="input-group-text input-group-text-sm px-1">%</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 pl-lg-0">
                <div class="input-group input-group-sm">
                    <input type="number" min="0" step="1" name="discount3" class="form-control  form-control-smp r-0" [(ngModel)]="record.discount3" (change)="calculateGrossDetail()" (keyup)="calculateGrossDetail()"   />
                    <div class="input-group-prepend input-group-prepend-sm">
                        <span class="input-group-text input-group-text-sm px-1">%</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 text-lg-right"><label>IVA</label></div>
            <div class="col-lg-2 ">
                <div class="input-group input-group-sm">
                    <input type="number" min="0" step="1" name="tax" class="form-control  form-control-sm pr-0" [(ngModel)]="record.tax" (change)="calculateGrossDetail()" (keyup)="calculateGrossDetail()"   />
                    <div class="input-group-prepend ">
                        <span class="input-group-text input-group-text-sm px-1">%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-10 text-lg-right"><label>Totale</label></div>
            <div class="col-lg-2">
                <div class="input-group input-group-sm">
                    <input type="number" min="0" step="0.01" name="gross" class="form-control form-control-sm pr-0" [(ngModel)]="record.gross"  (change)="calculateNetDetail()" (keyup)="calculateNetDetail()"  />
                    <div class="input-group-prepend input-group-prepend-sm">
                        <span class="input-group-text input-group-text-sm px-1">&euro;</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-lg-2"><label>Anagrafica</label></div>
            <div class="col-lg-10">
                <app-selectfieldcustom [recordSelected]="record.addressItem" [service]="addressesService" (OnAdd)="addAddress()" [fieldRender]="'name'" [class]="'form-control form-control-sm'" (OnRecordSelected)="record.addressItem=$event;record.addressItem?record.id_address=record.addressItem.id:record.id_address=0;"></app-selectfieldcustom>
                <div class="alert alert-light" *ngIf="record.addressItem">
                    <small><i class="fa fa-user"></i>&nbsp;{{record.addressItem.address}}, {{record.addressItem.city}}, 
                    <span *ngIf="record.addressItem.vat_number">P.IVA {{record.addressItem.vat_number}}</span></small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <div class="form-group">
                    <label>Note</label>
                    <div class="input-group input-group-sm">
                        <textarea  class="form-control  form-control-sm" rows="3" name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                    </div> 
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <label>Operatore</label>
                    <div class="input-group input-group-sm">
                        <select class="form-control form-control-sm" [(ngModel)]="record.id_user" name="id_user" #id_user="ngModel" required>
                            <option *ngFor="let t of users" [ngValue]="t.id">{{t.username}}</option>
                        </select>
                        <div *ngIf="id_user.invalid && (id_user.dirty || id_user.touched)" class="alert alert-danger">
                            <div *ngIf="id_user.errors.required">Campo obbligatorio.</div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>

    </form> 
    
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>
