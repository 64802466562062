import { Component, OnInit,Input ,Output,EventEmitter} from '@angular/core';
import { Globals } from '../../globals';

@Component({
  selector: 'app-selecttables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.sass']
})
export class SelectTablesComponent implements OnInit {

  constructor() { }

  @Input()
  value:any;

  @Input()
  name:string;

  @Input()
  show_all:boolean=false;

  tables=[];

  @Output() onchange = new EventEmitter();


  ngOnInit(): void {
    for(let m of Globals.parameters.modules){
      if(m.table!="" && m.table!=null && m.table!=undefined){
        let t={};
        t['table']=m.table;
        t['label']=m.label;
        this.tables.push(t);
      }
    }



  }

  onChange(){
    this.onchange.emit(this.value);
  }

}
